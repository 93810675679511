import { Flex } from '@chakra-ui/react'
import { Icon, type IconName } from 'components/Icon/Icon'
import type React from 'react'

interface IconPickerProps {
  icons: IconName[]
  selectedIcon: string
  iconColor: string
  setSelectedIcon: (icon: IconName) => void
}

export const IconPicker: React.FC<IconPickerProps> = ({
  icons,
  selectedIcon,
  iconColor,
  setSelectedIcon,
}) => {
  return (
    <Flex flexWrap="wrap">
      {icons.map((icon) => {
        const isSelected = icon === selectedIcon

        return (
          <Flex
            as="button"
            justifyContent="center"
            alignItems="center"
            flexShrink={0}
            width={8}
            height={8}
            key={icon}
            aria-selected={isSelected}
            onClick={() => setSelectedIcon(icon)}
            _selected={{ bg: 'gray.200' }}
            _hover={{ bg: isSelected ? 'gray.200' : 'gray.100' }}
          >
            <Icon name={icon} hexColor={iconColor} size="small" />
          </Flex>
        )
      })}
    </Flex>
  )
}
