import { type DYNAMIC_DATE_ID, getDatePreset } from 'constants/getDatePresets'
import { Box, Flex, MenuButton } from '@chakra-ui/react'
import { DatePreview } from 'components/Datepicker/DatePreview'
import { Dropdown } from 'components/Dropdown'
import { Icon } from 'components/Icon/Icon'
import { Typography } from 'components/Typography'
import { type SegmentationSet } from 'features/productFeed/graphql/fragments'
import type React from 'react'
import { PRODUCT_FEED_DATE_RANGES } from '../../SegmentationSetsView/consts'

interface SegmentRuleHeaderProps {
  segmentationSet: SegmentationSet
  selectedDatePresetId: DYNAMIC_DATE_ID
  setSelectedDatePresetId: (datePresetId: DYNAMIC_DATE_ID) => void
}

export const SegmentRuleHeader: React.FC<SegmentRuleHeaderProps> = ({
  segmentationSet,
  selectedDatePresetId,
  setSelectedDatePresetId,
}) => {
  const selectedDatePreset = getDatePreset(selectedDatePresetId)

  return (
    <Flex
      py={2}
      px={6}
      alignItems="center"
      justifyContent="space-between"
      borderBottom="1px solid"
      borderColor="gray.200"
    >
      <Flex alignItems="center">
        <Typography fontSize="medium" fontWeight={500} color="gray.900">
          Segmentation sets
        </Typography>

        <Typography color="gray.600" fontSize="sm" ml={2} mr={1}>
          /
        </Typography>

        <Flex alignItems="center" gap={2}>
          <Icon
            name={segmentationSet.iconName}
            color={segmentationSet.iconColor}
            size="small"
          />
          <Typography fontSize="medium" fontWeight={500} color="gray.900">
            {segmentationSet.name}
          </Typography>
        </Flex>
      </Flex>

      <Box>
        <Dropdown
          customMenuButton={
            <MenuButton
              as={DatePreview}
              actualDate={selectedDatePreset.value}
              badgeLabel={selectedDatePreset.name}
            />
          }
          size="sm"
          matchWidth={false}
          placement="bottom-end"
          options={PRODUCT_FEED_DATE_RANGES.map((dateRange) => ({
            id: dateRange,
            name: getDatePreset(dateRange).name,
          }))}
          value={selectedDatePresetId}
          callback={(value) => {
            setSelectedDatePresetId(value.id)
          }}
          renderInPortal={false}
        />
      </Box>
    </Flex>
  )
}
