import { onError } from '@apollo/client/link/error'
import * as Sentry from '@sentry/react'
import { removeDashboardLocalStorageKeys } from 'features/dashboard/hooks/useDashboardState'
import { getApolloClient } from './index'

enum ErrorCode {
  UNAUTHENTICATED = 'UNAUTHENTICATED',
  FORBIDDEN = 'FORBIDDEN',
}

export const errorLink = onError(
  ({ operation, graphQLErrors, networkError }) => {
    Sentry.withScope((scope) => {
      scope.setTransactionName(operation.operationName)
      scope.setContext('graphql', {
        operationName: operation.operationName,
        variables: JSON.stringify(operation.variables, null, 2),
      })
      scope.setTags({
        operationName: operation.operationName,
        mechanism: 'graphql',
      })

      if (graphQLErrors && graphQLErrors?.length > 0) {
        for (const error of graphQLErrors) {
          const errorCode = (error.extensions ?? {}).code as string

          Sentry.captureMessage(`GraphQL Error: ${error.message}`, {
            level: 'error',
            fingerprint: ['{{ default }}', '{{ transaction }}'],
            tags: {
              errorType: 'graphql',
              errorCode,
            },
            contexts: {
              'Error Details': {
                error: JSON.stringify(error, null, 2),
              },
            },
          })

          // Ignore auth errors and exit
          if (
            errorCode === ErrorCode.UNAUTHENTICATED ||
            errorCode === ErrorCode.FORBIDDEN
          ) {
            window.Clerk?.signOut()
            getApolloClient().clearStore()
            removeDashboardLocalStorageKeys()

            return
          }
        }
      }

      if (networkError) {
        Sentry.captureMessage(`Network Error: ${networkError.message}`, {
          level: 'error',
          tags: {
            errorType: 'network',
          },
          contexts: {
            'Error Details': {
              error: JSON.stringify(networkError, null, 2),
            },
          },
        })
      }
    })
  },
)
