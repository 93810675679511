import { Box, Flex, Table, Tbody, Td, Tr } from '@chakra-ui/react'
import { Button } from 'components/buttons'
import { Icon } from 'components/Icon/Icon'
import { TitleLayoutView } from 'components/Layouts/TitleLayoutView'
import { SkeletonTableRows } from 'components/Skeleton/SkeletonTableRows'
import { Typography } from 'components/Typography'
import { useProductLabels } from 'features/productFeed/graphql/useProductLabels'
import type React from 'react'
import { EmptyData } from 'shared/EmptyData/EmptyData'
import { AddProductLabelModal } from './components/AddProductLabelModal'
import { DeleteProductLabelModal } from './components/DeleteProductLabelModal'
import { EditProductLabelModal } from './components/EditProductLabelModal'

export const ProductLabelsView: React.FC = () => {
  const { productLabels, query } = useProductLabels()

  return (
    <TitleLayoutView
      title="Product labels"
      description="Manage the product labels used to automatically segment your products."
      size="sm"
    >
      <Box mt={10}>
        <AddProductLabelModal
          modalButton={<Button size="sm">Add product label</Button>}
        />
      </Box>
      <Table mt={6}>
        <Tbody>
          {query.loading ? (
            <SkeletonTableRows rows={5} columns={1} />
          ) : productLabels.length === 0 ? (
            <Tr>
              <EmptyData label="You haven’t added any product labels yet" />
            </Tr>
          ) : (
            productLabels.map((label) => (
              <Tr display="flex" key={label.id}>
                <Td px={0} flexGrow={1} alignContent="center">
                  <Flex alignItems="center" gap={2} px={3}>
                    <Icon
                      name={label.iconName}
                      size="small"
                      color={label.iconColor}
                    />
                    <Typography
                      fontSize="xs"
                      fontWeight={500}
                      color="gray.800"
                      lineHeight={4}
                    >
                      {label.name}
                    </Typography>
                  </Flex>
                </Td>
                <Td px={0}>
                  <EditProductLabelModal currentLabel={label} />
                  <DeleteProductLabelModal label={label} />
                </Td>
              </Tr>
            ))
          )}
        </Tbody>
      </Table>
    </TitleLayoutView>
  )
}
