import { format, toZonedTime } from 'date-fns-tz'
import { ChartSortOrder, type ChartSerie } from 'graphql/reports/types'
import { METRIC_FORMAT } from 'graphql/statistics/constants'
import {
  type NormalizedStatistic,
  type Statistic,
} from 'graphql/statistics/types'
import { type NormalizedDimensions } from 'graphql/statistics/useDimensions'
import { type NormalizedMetrics } from 'graphql/statistics/useMetrics'
import type {
  ChartZoomingOptions,
  Options,
  SeriesOptionsType,
} from 'highcharts'
import { difference } from 'lodash-es'
import { colorTheme } from 'ui/theme/colors'
import {
  chartTypes,
  type ChartTypeId,
  CHART_TYPE_ID,
} from 'utils/chart/chartTypes'
import {
  getDateTimeLabelFormats,
  sortByNumberOrString,
  xAxisFormatter,
  yAxisFormatter,
  getAxisType,
  getXAxisLabel,
  getXAxisValue,
} from 'utils/chart/common'
import {
  AXIS_TYPE,
  CHART_COMPARE_COLOR,
  DATETIME_FORMAT,
  SERIES_MAP_KEY,
  compareColor,
  getChartOffsetColorFromIndex,
  isTimeUnit,
  normalizedTimeDimensions,
  seriesMap,
  staticChartOptions,
  yAxisZeroPlotLine,
} from 'utils/chart/constants'
import type { GroupedChartData } from 'utils/chart/types'
import { formatMetricLabel } from 'utils/formatMetricLabel'
import { parseDate } from 'utils/parseDate'
import { v4 as uuid } from 'uuid'
import { getTooltipFormatter } from './ChartTooltip'
import { getGroupLabel } from './common'
import {
  type SeriesChartOptionsProps,
  type GroupedChartDataProps,
} from './types'

const maxNumberOfCategories = 1000

const getTimestamp = (dateAtIndex: Date, timezone = '') => {
  const parsedDate = parseDate(format(dateAtIndex, DATETIME_FORMAT), timezone)

  return toZonedTime(parsedDate, timezone).getTime()
}

export const getGroupedChartDataForBaseChart = ({
  data,
  groupBy,
  xAxis,
  serie: { key },
  timezone,
}: GroupedChartDataProps): GroupedChartData => {
  const getRowKeyValuePair = (
    row: NormalizedStatistic,
    valueKey: keyof Statistic,
  ): [string | number, number] => {
    const value = Number(row[key]?.[valueKey] ?? 0)

    if (!isTimeUnit(xAxis)) {
      const xAxisValue = row[xAxis]?.formattedValue ?? '0'

      return [xAxisValue, value]
    }

    const timestamp = getTimestamp(
      new Date(row[xAxis]?.[valueKey] ?? 0),
      timezone,
    )

    return [timestamp, value]
  }

  return (data ?? []).reduce<GroupedChartData>((acc, row) => {
    const groupLabel = getGroupLabel(groupBy, row)
    const [groupKey, value] = getRowKeyValuePair(row, 'value')
    const group = acc[groupLabel] ?? {}

    group[groupKey] = (group[groupKey] ?? 0) + value
    acc[groupLabel] = group

    if (groupBy === compareColor.id) {
      const compareGroupLabel = seriesMap[SERIES_MAP_KEY.COMPARE]
      const [, compareValue] = getRowKeyValuePair(row, 'comparisonValue')
      const compareGroup = acc[compareGroupLabel] ?? {}

      // We use groupKey instead of compareGroupKey to group values in the chart. The tooltip takes care of showing the correct value.
      compareGroup[groupKey] = (compareGroup[groupKey] ?? 0) + compareValue
      acc[compareGroupLabel] = compareGroup
    }

    return acc
  }, {})
}

const chartTypeZIndexMap: Partial<Record<ChartTypeId, number>> = {
  area: 10,
  column: 20,
  spline: 30,
  line: 30,
}

type TransformSeriesForChartProps = Omit<
  SeriesChartOptionsProps,
  'dateState' | 'data' | 'timezone' | 'compareUnit'
>

const transformSeriesForChart = ({
  groupedData,
  groupBy,
  series,
  normalizedMetrics,
  xAxis,
  currency,
  chartSorting,
}: TransformSeriesForChartProps) => {
  const hasColumnSeries = series.some(
    ({ type }) => chartTypes[type].type === CHART_TYPE_ID.COLUMN,
  )
  const xAxisType = getAxisType(
    xAxis,
    !!normalizedMetrics[xAxis],
    hasColumnSeries,
  )
  const isCategoryAxis = xAxisType === AXIS_TYPE.CATEGORY
  const isTimeAxis = isTimeUnit(xAxis)
  const xAxisId = `${xAxis}`
  const isMultiMetric = series.length > 1
  let categories: string[] | undefined = undefined

  if (isCategoryAxis) {
    categories = Array.from(
      new Set(
        series.flatMap(({ key }) => {
          const groupedDataEntries = Object.entries(
            groupedData[key] as GroupedChartData,
          )
          const data = groupedDataEntries.map(([, value]) =>
            value ? Object.entries(value) : [],
          )

          return data.flatMap((values) => values.map(([key]) => String(key)))
        }),
      ),
    )

    if (isTimeAxis) {
      // Sort by date ascending
      categories.sort((first, second) => sortByNumberOrString(second, first))
    } else if (series.length > 0) {
      const isMetricCompare = !!series.find(
        ({ key }) => key === chartSorting?.key,
      )
      const compareSerieKey =
        (isMetricCompare && chartSorting?.key) || series[0].key

      const groupedDataEntries = Object.entries(
        groupedData[compareSerieKey] as GroupedChartData,
      )

      if (groupedDataEntries[0]) {
        const [, value] = groupedDataEntries[0]
        let data = value ? Object.entries(value) : []

        // Some categories may be missing, so find them and add a default 0
        const missingCategories = difference(
          categories,
          data.map(([key]) => key),
        )

        data = data.concat(missingCategories.map((key) => [key, 0]))
        data.sort(([, first], [, second]) =>
          isMetricCompare && chartSorting?.order === ChartSortOrder.ASC
            ? sortByNumberOrString(second ?? 0, first ?? 0)
            : sortByNumberOrString(first ?? 0, second ?? 0),
        )

        if (isMetricCompare) {
          const xAxisGroupOrder = data.map(([key]) => key)

          categories.sort(
            (first, second) =>
              xAxisGroupOrder.indexOf(String(first)) -
              xAxisGroupOrder.indexOf(String(second)),
          )
        } else {
          categories.sort((first, second) =>
            chartSorting?.order === ChartSortOrder.ASC
              ? String(first).localeCompare(String(second))
              : String(second).localeCompare(String(first)),
          )
        }
      }
    }
  }

  const transformedSeries = series.flatMap(({ key, type }, index) => {
    const chartId = `${type}-${xAxis}-${key}-${groupBy}`
    const seriesUuid = uuid()
    const groupedDataEntries = Object.entries(
      groupedData[key] as GroupedChartData,
    )

    return groupedDataEntries.map(([groupName, value], groupIndex) => {
      const name = isMultiMetric
        ? formatMetricLabel(normalizedMetrics[key], currency)
        : groupName
      let data = value ? Object.entries(value) : []

      if (isCategoryAxis) {
        // Some categories may be missing, so find them and add a default 0
        const missingCategories = difference(
          categories,
          data.map(([key]) => key),
        )

        data = data.concat(missingCategories.map((key) => [key, 0]))
        // Sort by values and get top categories
        data.sort(([first], [second]) => {
          if (!categories) return 0

          return categories.indexOf(first) - categories.indexOf(second)
        })
      } else if (isTimeAxis) {
        // Sort by date ascending
        data.sort(([first], [second]) => sortByNumberOrString(second, first))
      } else {
        // Sort by key (x axis values) descending
        data.sort(([first], [second]) => sortByNumberOrString(first, second))
      }

      const isCompareSeries =
        groupBy === compareColor.id &&
        groupName === seriesMap[SERIES_MAP_KEY.COMPARE]

      return {
        type: chartTypes[type].type,
        metadata: {
          isCompareSeries,
          metric: normalizedMetrics[key],
        },
        stacking: chartTypes[type].stack ? 'normal' : undefined,
        data: data.map(([dataKey, value]) => [
          getXAxisValue(xAxisType, dataKey),
          value,
        ]),
        name,
        id: `${chartId}-${seriesUuid}-${groupName}`,
        linkedTo:
          isCompareSeries && isMultiMetric
            ? `${chartId}-${seriesUuid}-${seriesMap[SERIES_MAP_KEY.ACTUAL]}`
            : undefined,
        index, // This decides the order of the series in the chart
        color: !isCompareSeries
          ? staticChartOptions.colors[
              (isMultiMetric ? index : groupIndex) %
                staticChartOptions.colors.length
            ]
          : !isMultiMetric
            ? CHART_COMPARE_COLOR
            : getChartOffsetColorFromIndex(index),
        xAxis: xAxisId,
        yAxis:
          normalizedMetrics[key]?.format === METRIC_FORMAT.CURRENCY
            ? currencyYAxisId
            : key,
        zIndex:
          // we want the chart type to be prio one in order
          // then should choose actual over compare
          (chartTypeZIndexMap[type] || 0) + (isCompareSeries ? 0 : 1),
        pointPadding: 0,
        groupPadding: 0.1,
        marker: {
          enabled: false,
        },
        legendSymbol: 'rectangle',
      }
    })
  })

  categories?.slice(0, maxNumberOfCategories)

  return { series: transformedSeries, categories }
}

interface GetXAxesProps {
  xAxis: string
  normalizedDimensions: NormalizedDimensions
  normalizedMetrics: NormalizedMetrics
  categories: string[] | undefined
  groupBy: string | null
  series: ChartSerie[]
  currency: string | undefined
}

const getXAxes = ({
  xAxis,
  normalizedDimensions,
  normalizedMetrics,
  categories,
  series,
  currency,
}: GetXAxesProps): Highcharts.XAxisOptions[] => {
  const hasColumnSeries = series.some(
    ({ type }) => chartTypes[type].type === CHART_TYPE_ID.COLUMN,
  )
  const xAxisType = getAxisType(
    xAxis,
    !!normalizedMetrics[xAxis],
    hasColumnSeries,
  )
  const isTimeAxis = isTimeUnit(xAxis)
  const xAxisLabel = getXAxisLabel(
    xAxis,
    undefined,
    normalizedDimensions[xAxis],
    normalizedMetrics[xAxis],
    currency,
  )

  return [
    {
      ...staticChartOptions.xAxis,
      type: xAxisType,
      dateTimeLabelFormats: isTimeAxis
        ? getDateTimeLabelFormats(xAxis)
        : undefined,
      categories,
      title: {
        ...staticChartOptions.xAxis.title,
        text: isTimeAxis ? normalizedTimeDimensions[xAxis].name : xAxisLabel,
      },
      visible: true,
      labels: {
        ...staticChartOptions.xAxis.labels,
        autoRotationLimit: 300,
        formatter: xAxisFormatter(xAxis, normalizedMetrics[xAxis]),
      },
      id: `${xAxis}`,
      crosshair:
        hasColumnSeries || xAxisType === AXIS_TYPE.CATEGORY
          ? {
              color: colorTheme.grey[100],
              dashStyle: 'Solid',
            }
          : {
              color: colorTheme.grey[300],
              dashStyle: 'ShortDot',
            },
    } as Highcharts.XAxisOptions,
  ]
}

interface GetYAxesProps {
  series: ChartSerie[]
  normalizedMetrics: NormalizedMetrics
  currency: string | undefined
}
const maxNumberOfYAxisMetrics = 2
const currencyYAxisId = 'yAxis-currency'
const getYAxes = ({ series, normalizedMetrics, currency }: GetYAxesProps) => {
  const currencyMetrics = series.filter(
    ({ key }) => normalizedMetrics[key]?.format === METRIC_FORMAT.CURRENCY,
  )
  const restMetrics = series.filter(
    ({ key }) => normalizedMetrics[key]?.format !== METRIC_FORMAT.CURRENCY,
  )
  const yAxesVisible =
    (currencyMetrics.length ? 1 : 0) + restMetrics.length <=
    maxNumberOfYAxisMetrics
  const yAxes = []
  const isLineChart = series.find(
    ({ type }) => type === CHART_TYPE_ID.LINE || type === CHART_TYPE_ID.SPLINE,
  )

  const getYAxis = ({ key }: ChartSerie) => {
    const isCurrencyAxis =
      normalizedMetrics[key]?.format === METRIC_FORMAT.CURRENCY
    const hasMultipleCurrencyMetrics =
      isCurrencyAxis && currencyMetrics.length > 1

    return {
      ...staticChartOptions.yAxis,
      title: {
        ...staticChartOptions.yAxis.title,
        text: hasMultipleCurrencyMetrics
          ? `Currency (${currency})`
          : formatMetricLabel(normalizedMetrics[key], currency),
      },
      labels: {
        ...staticChartOptions.yAxis.labels,
        formatter: yAxisFormatter(normalizedMetrics[key]?.format),
      },
      visible: yAxesVisible,
      id: isCurrencyAxis ? currencyYAxisId : key,
      opposite: yAxes.length >= 1,
      plotLines: isLineChart ? yAxisZeroPlotLine : [],
    }
  }

  if (currencyMetrics.length) {
    yAxes.push(getYAxis(currencyMetrics[0]))
  }

  if (restMetrics.length) {
    restMetrics.forEach((serie) => yAxes.push(getYAxis(serie)))
  }

  return yAxes
}

export const getChartOptionsForBaseChart = ({
  groupedData,
  groupBy,
  series,
  normalizedDimensions,
  normalizedMetrics,
  xAxis,
  currency,
  dateState,
  data,
  timezone,
  compareUnit,
  chartSorting,
}: SeriesChartOptionsProps): Highcharts.Options => {
  const { series: transformedSeries, categories } = transformSeriesForChart({
    groupedData,
    groupBy,
    series,
    normalizedDimensions,
    normalizedMetrics,
    xAxis,
    currency,
    chartSorting,
  })

  return {
    ...staticChartOptions,
    plotOptions: {
      ...staticChartOptions.plotOptions,
      series: {
        ...staticChartOptions.plotOptions.series,
        stickyTracking: true,
      },
      line: {
        threshold: series.length > 1 ? 0 : undefined, // This is to make charts with more than one axis to align the axis at 0
      },
      spline: {
        threshold: series.length > 1 ? 0 : undefined, // This is to make charts with more than one axis to align the axis at 0
      },
    } satisfies Options['plotOptions'],
    chart: {
      ...staticChartOptions.chart,
      inverted: false,
      alignThresholds: true,
      zooming: { type: 'xy' } satisfies ChartZoomingOptions,
    },
    xAxis: getXAxes({
      xAxis,
      normalizedDimensions,
      normalizedMetrics,
      categories,
      groupBy,
      currency,
      series,
    }),
    yAxis: getYAxes({ series, normalizedMetrics, currency }),
    tooltip: {
      ...staticChartOptions.tooltip,
      shared: true,
      formatter: getTooltipFormatter({
        xAxis,
        series,
        dateState,
        normalizedMetrics,
        currency,
        groupBy,
        data,
        timezone,
        compareUnit,
      }),
    },
    legend: {
      ...staticChartOptions.legend,
      enabled: !!groupBy || transformedSeries.length > 1,
    },
    series: transformedSeries as SeriesOptionsType[],
    boost: {
      ...staticChartOptions.boost,
      enabled: false,
    },
  }
}
