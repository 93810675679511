import { useMutation } from '@apollo/client'
import { graphql } from 'generated/graphql'

const SET_SEGMENTATION_RULES_MUTATION = graphql(/* GraphQL */ `
  mutation SetSegmentationRules($params: SetSegmentationRulesInput!) {
    setSegmentationRules(params: $params) {
      ...SegmentationRuleFields
    }
  }
`)

export const useSetSegmentationRules = () => {
  return useMutation(SET_SEGMENTATION_RULES_MUTATION)
}
