import {
  Flex,
  Menu,
  Td,
  Tr,
  IconButton,
  MenuButton,
  MenuList,
  Portal,
  MenuDivider,
} from '@chakra-ui/react'
import { MenuOverlay } from 'components/Dropdown/components/MenuOverlay'
import { Icon } from 'components/Icon/Icon'
import { Typography } from 'components/Typography'
import { PRODUCT_FEED_PAGES } from 'features/productFeed/routePages'
import type React from 'react'
import { Link } from 'react-router-dom'
import { formatDate } from 'utils/formatDate'
import { type SegmentationSet } from '../../../graphql/fragments'
import { DeleteSegmentationSetModal } from './DeleteSegmentationSetModal'
import { EditSegmentationSetModal } from './EditSegmentationModal'

interface SegmentRowProps {
  segmentationSet: SegmentationSet
}

export const SegmentRow: React.FC<SegmentRowProps> = ({ segmentationSet }) => {
  return (
    <Tr>
      <Td>
        <Flex alignItems="center" gap={2}>
          <Icon
            size="small"
            name={segmentationSet.iconName}
            color={segmentationSet.iconColor}
          />
          <Link
            to={PRODUCT_FEED_PAGES.SEGMENTATION_RULES_BY_ID(segmentationSet.id)}
          >
            {segmentationSet.name}
          </Link>
        </Flex>
      </Td>
      <Td>
        <Typography>
          {formatDate(new Date(segmentationSet.updatedAt))}
        </Typography>
      </Td>
      <Td>
        <Menu isLazy={false} size="sm" closeOnSelect={false}>
          {({ isOpen }) => (
            <>
              <MenuButton
                onClick={
                  (e) => e.stopPropagation() // For example when context menu is used in report list, it will stop the report from being selected on click
                }
                as={IconButton}
                size="sm"
                aria-label="Options"
                border="none"
                icon={
                  <Icon
                    name="HorizontalMenuIcon"
                    tooltipLabel="Actions"
                    size="small"
                  />
                }
                variant="ghost"
              />
              <Portal>
                <MenuOverlay isOpen={isOpen} />
                <MenuList>
                  <EditSegmentationSetModal
                    currentSegmentationSet={segmentationSet}
                  />

                  <MenuDivider />

                  <DeleteSegmentationSetModal
                    segmentationSet={segmentationSet}
                  />
                </MenuList>
              </Portal>
            </>
          )}
        </Menu>
      </Td>
    </Tr>
  )
}
