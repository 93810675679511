import { Box } from '@chakra-ui/react'
import {
  type SegmentationSetByIdQueryResult,
  useSegmentationSetById,
} from 'features/productFeed/graphql/useSegmentationSetById'
import { useState } from 'react'
import { useParams } from 'react-router'
import { RulesContainer } from './components/RulesContainer/RulesContainer'
import { SegmentRuleHeader } from './components/SegmentRuleHeader'

export const SegmentationRulesView: React.FC = () => {
  const { id } = useParams()
  const { segmentationSet, query } = useSegmentationSetById(id ?? '')

  // TODO add better loading states
  if (query.loading) return <Box>loading...</Box>

  if (query.error || !segmentationSet) return <Box>Not found</Box>

  return <SegmentationContent segmentationSet={segmentationSet} />
}

const SegmentationContent: React.FC<{
  segmentationSet: SegmentationSetByIdQueryResult
}> = ({ segmentationSet }) => {
  const [selectedDatePresetId, setSelectedDatePresetId] = useState(
    segmentationSet.dynamicDate,
  )

  return (
    <Box>
      <SegmentRuleHeader
        segmentationSet={segmentationSet}
        selectedDatePresetId={selectedDatePresetId}
        setSelectedDatePresetId={setSelectedDatePresetId}
      />

      <Box p={4}>
        <RulesContainer
          segmentationSet={segmentationSet}
          selectedDatePresetId={selectedDatePresetId}
        />
      </Box>
    </Box>
  )
}
