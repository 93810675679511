import {
  Box,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
} from '@chakra-ui/react'
import { Button } from 'components/buttons/Button'
import { type IconName } from 'components/Icon/Icon'
import Input from 'components/Input/Input'

import { ColorPicker } from 'components/Pickers/ColorPicker'
import { GenericPickerModal } from 'components/Pickers/GenericPickerModal'
import { IconPicker } from 'components/Pickers/IconPicker'
import { Typography } from 'components/Typography'
import { type CreateProductLabelInput } from 'generated/graphql/graphql'
import {
  useDefaultDisclosure,
  type DefaultDisclosure,
} from 'hooks/useDefaultDisclosure'
import { type FC, useState, type ReactElement, useRef } from 'react'
import { colorTheme } from 'ui/theme/colors'
import { type ProductLabel } from '../graphql/fragments'

const labelColors: string[] = [
  colorTheme.gray[500],
  colorTheme.purple[500],
  colorTheme.magenta[500],
  colorTheme.red[400],
  colorTheme.orange[500],
  colorTheme.yellow[600],
  colorTheme.green[500],
  colorTheme.dema[600],
  colorTheme.cyan[600],
  colorTheme.blue[600],
  colorTheme.black,
]

const labelIconNames: IconName[] = [
  'BikeIcon',
  'CarIcon',
  'ShippingTruckIcon',
  'SailboatIcon',
  'RocketIcon',
  'BlockIcon',
  'ExclamationCircleIcon',
  'ExclamationTriangleIcon',
  'ParkingIcon',
  'DiamondIcon',
  'MedalIcon',
  'HeartIcon',
  'StarIcon',
  'BadgeIcon',
  'DollarCoinIcon',
  'PiggyBankIcon',
  'Percent1Icon',
  'BadgeDiscountIcon',
  'TagIcon',
  'WarehouseIcon',
]

export type UpdatedProductLabel = CreateProductLabelInput & { id?: string }

const defaultLabel: UpdatedProductLabel = {
  iconColor: labelColors[0],
  name: '',
  iconName: labelIconNames[0],
}

type ProductLabelPickerProps = {
  currentLabel: ProductLabel | undefined
  modalButton: ReactElement
  isEditMode?: boolean
  onApply: (label: UpdatedProductLabel) => void
  disclosure?: DefaultDisclosure
}

export const ProductLabelPicker: FC<ProductLabelPickerProps> = ({
  currentLabel,
  modalButton,
  isEditMode,
  onApply,
  disclosure,
}) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const [label, setLabel] = useState<UpdatedProductLabel>(
    currentLabel ?? defaultLabel,
  )
  const { isOpen, onOpen, onClose } = useDefaultDisclosure(disclosure)

  return (
    <GenericPickerModal
      isOpen={isOpen}
      onClose={onClose}
      onOpen={onOpen}
      modalButton={modalButton}
      setToDefaultState={() => {
        if (!currentLabel) {
          setLabel(defaultLabel)
        }
      }}
      initialFocusRef={inputRef}
    >
      <ModalHeader>
        {isEditMode ? 'Edit product label' : 'Add product label'}
      </ModalHeader>
      <ModalCloseButton />

      <ModalBody>
        <Typography mb={4}>
          Choose a color and an icon for the product label.
        </Typography>
        <Box mb={6}>
          <ColorPicker
            colors={labelColors}
            selectedColor={label.iconColor}
            setSelectedColor={(iconColor) =>
              setLabel((label) => ({ ...label, iconColor }))
            }
          />
        </Box>

        <IconPicker
          iconColor={label.iconColor}
          icons={labelIconNames}
          selectedIcon={label.iconName}
          setSelectedIcon={(iconName) =>
            setLabel((label) => ({ ...label, iconName }))
          }
        />

        <Input
          ref={inputRef}
          label="Product label name"
          title="Product label name"
          placeholder="" // TODO: revisit
          value={label.name}
          maxLength={25}
          onChange={(e) =>
            setLabel((label) => ({ ...label, name: e.target.value }))
          }
        />
      </ModalBody>

      <ModalFooter>
        <Button colorScheme="gray" variant="outline" mr={2} onClick={onClose}>
          Cancel
        </Button>
        <Button
          isDisabled={!label.name || !label.iconColor || !label.iconName}
          variant="solid"
          type="submit"
          colorScheme="primary"
          onClick={() => {
            onApply(label)
            onClose()
          }}
        >
          {isEditMode ? 'Save' : 'Add product label'}
        </Button>
      </ModalFooter>
    </GenericPickerModal>
  )
}
