import {
  COMPARE_DYNAMIC_DATE_ID,
  DYNAMIC_DATE_ID,
} from 'constants/getDatePresets'
import { FilterOperator } from 'components/Filters/types'
import { ChartSortOrder } from 'graphql/reports/types'
import { type DashboardState } from '../hooks/useDashboardState'
import { DASHBOARD_ID } from './consts'
import { GLOBAL_FILTER } from './globalFilters'

export const inventoryDashboard = {
  id: DASHBOARD_ID.INVENTORY,
  label: 'Inventory',
  globalFilterIds: [
    GLOBAL_FILTER.BRAND,
    GLOBAL_FILTER.CATEGORY,
    GLOBAL_FILTER.GENDER,
    GLOBAL_FILTER.WAREHOUSE,
    GLOBAL_FILTER.MATERIAL,
    GLOBAL_FILTER.COLLECTION,
    GLOBAL_FILTER.CUSTOM_ATTRIBUTE_1,
  ],
  dynamicDateRanges: [
    DYNAMIC_DATE_ID.TODAY,
    DYNAMIC_DATE_ID.THIS_WEEK,
    DYNAMIC_DATE_ID.THIS_MONTH,
    DYNAMIC_DATE_ID.THIS_YEAR_TO_DATE,
    DYNAMIC_DATE_ID.YESTERDAY,
    DYNAMIC_DATE_ID.LAST_7_DAYS,
    DYNAMIC_DATE_ID.LAST_14_DAYS,
    DYNAMIC_DATE_ID.LAST_28_DAYS,
    DYNAMIC_DATE_ID.LAST_WEEK,
    DYNAMIC_DATE_ID.LAST_MONTH,
  ],
  compareDynamicDateRanges: [
    COMPARE_DYNAMIC_DATE_ID.PRECEDING_PERIOD_MATCHING,
    COMPARE_DYNAMIC_DATE_ID.PRECEDING_PERIOD,
    COMPARE_DYNAMIC_DATE_ID.PRECEDING_MONTH,
    COMPARE_DYNAMIC_DATE_ID.PRECEDING_YEAR,
    COMPARE_DYNAMIC_DATE_ID.PRECEDING_YEAR_MATCHING_WEEKDAYS,
  ],
  defaultDateRange: DYNAMIC_DATE_ID.TODAY,
  widgets: [
    {
      id: 'inventory-donut-brands',
      title: 'Brand distribution',
      type: 'donut',
      gridSpan: 4,
      metrics: ['order:netProducts'],
      dimensions: ['brand'],
      filter: {
        brand: [
          {
            comparisonId: FilterOperator.isAnyOf,
            value: ['Dope', 'Montec'],
          },
        ],
      },
      hasAttribution: false,
    },
    {
      id: 'inventory-bar-brands-performance',
      type: 'bar',
      title: 'Brand performance overview',
      gridSpan: 4,
      dimensions: ['brand'],
      selectedMetric: 'order:netProducts',
      metrics: {
        'order:netProducts': {
          key: 'order:netProducts',
        },
        'product:seasonForecastMinusInventory': {
          key: 'product:seasonForecastMinusInventory',
        },
      },
      filter: {
        brand: [
          {
            comparisonId: FilterOperator.isAnyOf,
            value: ['Dope', 'Montec'],
          },
        ],
      },
    },
    {
      id: 'inventory-bar-target-vs-sales-by-brand',
      type: 'bar',
      title: 'Target vs actual sales by brand',
      gridSpan: 4,
      dimensions: ['brand'],
      selectedMetric: 'product:netProductsSoldVsTarget',
      metrics: {
        'product:netProductsSoldVsTarget': {
          key: 'product:netProductsSoldVsTarget',
        },
      },
      filter: {
        brand: [
          {
            comparisonId: FilterOperator.isAnyOf,
            value: ['Dope', 'Montec'],
          },
        ],
      },
    },
    {
      id: 'inventory-donut-warehouses',
      title: 'Warehouse distribution',
      type: 'donut',
      gridSpan: 4,
      metrics: ['order:netProducts'],
      dimensions: ['warehouse'],
      filter: {
        warehouse: [
          {
            comparisonId: FilterOperator.isAnyOf,
            value: ['ryder', 'postnord'],
          },
        ],
      },
      hasAttribution: false,
    },
    {
      id: 'inventory-bar-warehouses-performance',
      type: 'bar',
      title: 'Warehouse performance overview',
      gridSpan: 4,
      dimensions: ['warehouse'],
      selectedMetric: 'order:netProducts',
      metrics: {
        'order:netProducts': {
          key: 'order:netProducts',
        },
        'product:seasonForecastMinusInventory': {
          key: 'product:seasonForecastMinusInventory',
        },
      },
      filter: {
        warehouse: [
          {
            comparisonId: FilterOperator.isAnyOf,
            value: ['ryder', 'postnord'],
          },
        ],
      },
    },
    {
      id: 'inventory-bar-target-vs-sales-by-warehouse',
      type: 'bar',
      title: 'Target vs actual sales by warehouse',
      gridSpan: 4,
      dimensions: ['warehouse'],
      selectedMetric: 'product:netProductsSoldVsTarget',
      metrics: {
        'product:netProductsSoldVsTarget': {
          key: 'product:netProductsSoldVsTarget',
        },
      },
      filter: {
        warehouse: [
          {
            comparisonId: FilterOperator.isAnyOf,
            value: ['ryder', 'postnord'],
          },
        ],
      },
    },
    {
      id: 'inventory-donut-categories',
      title: 'Category distribution',
      type: 'donut',
      gridSpan: 4,
      metrics: ['order:netProducts'],
      dimensions: ['category'],
      filter: {
        category: [
          {
            comparisonId: FilterOperator.isAnyOf,
            value: ['Snowboard Jacket', 'Snowboard Pants'],
          },
        ],
      },
      hasAttribution: false,
    },
    {
      id: 'inventory-bar-categories-performance',
      type: 'bar',
      title: 'Category performance overview',
      gridSpan: 4,
      dimensions: ['category'],
      selectedMetric: 'order:netProducts',
      metrics: {
        'order:netProducts': {
          key: 'order:netProducts',
        },
        'product:seasonForecastMinusInventory': {
          key: 'product:seasonForecastMinusInventory',
        },
      },
      filter: {
        category: [
          {
            comparisonId: FilterOperator.isAnyOf,
            value: ['Snowboard Jacket', 'Snowboard Pants'],
          },
        ],
      },
    },
    {
      id: 'inventory-bar-target-vs-sales-by-category',
      type: 'bar',
      title: 'Target vs actual sales by category',
      gridSpan: 4,
      dimensions: ['category'],
      selectedMetric: 'product:netProductsSoldVsTarget',
      metrics: {
        'product:netProductsSoldVsTarget': {
          key: 'product:netProductsSoldVsTarget',
        },
      },
      filter: {
        category: [
          {
            comparisonId: FilterOperator.isAnyOf,
            value: ['Snowboard Jacket', 'Snowboard Pants'],
          },
        ],
      },
    },
    {
      id: 'inventory-donut-lifecycle',
      title: 'Product lifecycle distribution',
      type: 'donut',
      gridSpan: 4,
      metrics: ['order:netProducts'],
      dimensions: ['customAttribute1'],
      filter: {
        customAttribute1: [
          {
            comparisonId: FilterOperator.isAnyOf,
            value: ['discontinued', 'new', 'carryOver'],
          },
        ],
      },
      hasAttribution: false,
    },
    {
      id: 'inventory-bar-lifecycle-performance',
      type: 'bar',
      title: 'Product lifecycle performance overview',
      gridSpan: 4,
      dimensions: ['customAttribute1'],
      selectedMetric: 'order:netProducts',
      metrics: {
        'order:netProducts': {
          key: 'order:netProducts',
        },
        'product:seasonForecastMinusInventory': {
          key: 'product:seasonForecastMinusInventory',
        },
      },
      filter: {
        customAttribute1: [
          {
            comparisonId: FilterOperator.isAnyOf,
            value: ['discontinued', 'new', 'carryOver'],
          },
        ],
      },
    },
    {
      id: 'inventory-bar-target-vs-sales-by-lifecycle',
      type: 'bar',
      title: 'Target vs actual sales by product lifecycle',
      gridSpan: 4,
      dimensions: ['customAttribute1'],
      selectedMetric: 'product:netProductsSoldVsTarget',
      metrics: {
        'product:netProductsSoldVsTarget': {
          key: 'product:netProductsSoldVsTarget',
        },
      },
      filter: {
        customAttribute1: [
          {
            comparisonId: FilterOperator.isAnyOf,
            value: ['discontinued', 'new', 'carryOver'],
          },
        ],
      },
    },
    {
      id: 'inventory-bar-products-sales',
      type: 'bar',
      title: 'Product sales overview',
      gridSpan: 12,
      dimensions: ['productName'],
      selectedMetric: 'order:netProducts',
      metrics: {
        'order:netProducts': {
          key: 'order:netProducts',
        },
      },
      sort: {
        key: 'order:netProducts',
        order: ChartSortOrder.DESC,
      },
    },
    {
      id: 'inventory-bar-products-sales-vs-target',
      type: 'bar',
      title: 'Top product sales vs target overview',
      gridSpan: 12,
      dimensions: ['productName'],
      selectedMetric: 'product:netProductsSoldVsTarget',
      metrics: {
        'product:netProductsSoldVsTarget': {
          key: 'product:netProductsSoldVsTarget',
        },
      },
      sort: {
        key: 'order:netProductsSoldVsTarget',
        order: ChartSortOrder.DESC,
      },
    },
    {
      id: 'inventory-bar-products-sales-vs-target',
      type: 'bar',
      title: 'Bottom product sales vs target overview',
      gridSpan: 12,
      dimensions: ['productName'],
      selectedMetric: 'product:netProductsSoldVsTarget',
      metrics: {
        'product:netProductsSoldVsTarget': {
          key: 'product:netProductsSoldVsTarget',
        },
      },
      sort: {
        key: 'order:netProductsSoldVsTarget',
        order: ChartSortOrder.ASC,
      },
    },
    {
      id: 'inventory-bar-products-target',
      type: 'bar',
      title: 'Product targets overview',
      gridSpan: 12,
      dimensions: ['productName'],
      selectedMetric: 'product:productsTarget',
      metrics: {
        'product:productsTarget': {
          key: 'product:productsTarget',
        },
      },
      sort: {
        key: 'order:netProductsSoldVsTarget',
        order: ChartSortOrder.DESC,
      },
    },
    {
      id: 'inventory-bar-products-end-of-season-positive',
      type: 'bar',
      title: 'Positive forecast inventory end of season',
      gridSpan: 12,
      dimensions: ['productName'],
      selectedMetric: 'product:seasonForecastMinusInventory',
      metrics: {
        'product:seasonForecastMinusInventory': {
          key: 'product:seasonForecastMinusInventory',
        },
      },
      filter: {
        'product:seasonForecastMinusInventory': [
          {
            comparisonId: FilterOperator.greaterThan,
            value: ['0'],
          },
        ],
      },
      sort: {
        key: 'order:seasonForecastMinusInventory',
        order: ChartSortOrder.DESC,
      },
    },
    {
      id: 'inventory-bar-products-end-of-season-negative',
      type: 'bar',
      title: 'Negative forecast inventory end of season',
      gridSpan: 12,
      dimensions: ['productName'],
      selectedMetric: 'product:seasonForecastMinusInventory',
      metrics: {
        'product:seasonForecastMinusInventory': {
          key: 'product:seasonForecastMinusInventory',
        },
      },
      filter: {
        'product:seasonForecastMinusInventory': [
          {
            comparisonId: FilterOperator.lessThan,
            value: ['0'],
          },
        ],
      },
      sort: {
        key: 'order:seasonForecastMinusInventory',
        order: ChartSortOrder.ASC,
      },
    },
  ],
} as const satisfies DashboardState['dashboards'][number]
