import { useToast } from '@chakra-ui/react'
import { type CreateSegmentationSetInput } from 'generated/graphql/graphql'
import { useTrackEvent } from 'graphql/events/useTrackEvent'
import { set } from 'lodash-es'
import cloneDeep from 'lodash-es/cloneDeep'
import { type ReactElement } from 'react'
import { useCreateSegmentationSet } from '../graphql/useCreateSegmentationSet'
import { SEGMENTATION_SETS_QUERY } from '../graphql/useSegmentationSets'
import { SegmentationSetPicker } from './SegmentationSetPicker'

interface AddSegmentationSetModalProps {
  modalButton: ReactElement
}

export const AddSegmentationSetModal: React.FC<
  AddSegmentationSetModalProps
> = ({ modalButton }) => {
  const [trackEvent] = useTrackEvent()
  const toast = useToast()
  const createSegmentationSet = useCreateSegmentationSet()

  const onApply = (segmentationSet: CreateSegmentationSetInput) => {
    if (!segmentationSet.name) return

    createSegmentationSet({
      variables: {
        params: {
          name: segmentationSet.name,
          iconColor: segmentationSet.iconColor,
          iconName: segmentationSet.iconName,
          dynamicDate: segmentationSet.dynamicDate,
        },
      },
      optimisticResponse: {
        createSegmentationSet: {
          id: 'optimistic',
          name: segmentationSet.name,
          iconName: segmentationSet.iconName,
          iconColor: segmentationSet.iconColor,
          dynamicDate: segmentationSet.dynamicDate,
          updatedAt: new Date().toISOString(),
          __typename: 'SegmentationSet',
        },
      },
      update: (cache, { data }) => {
        if (!data) return
        cache.updateQuery({ query: SEGMENTATION_SETS_QUERY }, (queryData) => {
          if (!queryData?.viewer?.merchant.segmentationSets) return queryData

          const queryDataClone = cloneDeep(queryData)

          set(queryDataClone, 'viewer.merchant.segmentationSets', [
            ...queryData.viewer.merchant.segmentationSets,
            data.createSegmentationSet,
          ])

          return queryDataClone
        })
      },
      onCompleted: ({ createSegmentationSet }) => {
        trackEvent({
          eventName: 'ProductFeed Segmentation Set Created',
          eventProperties: {
            name: createSegmentationSet.name,
            iconName: createSegmentationSet.iconName,
            iconColor: createSegmentationSet.iconColor,
          },
        })
      },
      onError: () => {
        toast({
          description: 'Could not add segmentation set. Try again.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      },
    })
  }

  return (
    <SegmentationSetPicker
      currentSegmentationSet={undefined}
      modalButton={modalButton}
      onApply={onApply}
    />
  )
}
