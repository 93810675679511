import {
  COMPARE_DYNAMIC_DATE_ID,
  DYNAMIC_DATE_ID,
} from 'constants/getDatePresets'
import type { DateRange } from 'constants/types'
import { getIsPopulatedDateRange } from 'components/Datepicker/utils'
import { toZonedTime } from 'date-fns-tz'
import { useCreateAnalyticsConfig } from 'graphql/reports/useCreateAnalyticsConfig'
import { merchantInfoAtom } from 'graphql/useMerchantInfo'
import { atom, useAtomValue, useSetAtom } from 'jotai'
import { focusAtom } from 'jotai-optics'
import { useCallback } from 'react'
import { analyticsConfigAtom } from '../atoms/reportViewStateAtom'
import {
  getAnalyticsConfigFromStore,
  getPresetFromUrlCompareDynamicDate,
  getPresetFromUrlDynamicDate,
} from '../utils/utils'
import { useIsNewReportFlow } from './useIsNewReportFlow'

export interface DateState {
  dateRange: DateRange
  compareDateRange: DateRange
  dynamicDate: DYNAMIC_DATE_ID | undefined
  compareDynamicDate: COMPARE_DYNAMIC_DATE_ID | undefined
  isCompare: boolean
}

export const dynamicDates = [
  DYNAMIC_DATE_ID.CUSTOM_RANGE,
  DYNAMIC_DATE_ID.TODAY,
  DYNAMIC_DATE_ID.YESTERDAY,
  DYNAMIC_DATE_ID.LAST_7_DAYS,
  DYNAMIC_DATE_ID.LAST_14_DAYS,
  DYNAMIC_DATE_ID.LAST_28_DAYS,
  DYNAMIC_DATE_ID.THIS_WEEK_TO_DATE,
  DYNAMIC_DATE_ID.LAST_WEEK,
  DYNAMIC_DATE_ID.THIS_MONTH_TO_DATE,
  DYNAMIC_DATE_ID.LAST_MONTH,
  DYNAMIC_DATE_ID.THIS_YEAR_TO_DATE,
  DYNAMIC_DATE_ID.LAST_YEAR,
  DYNAMIC_DATE_ID.LAST_12_MONTHS,
]

export const compareDynamicDates = [
  COMPARE_DYNAMIC_DATE_ID.CUSTOM_RANGE,
  COMPARE_DYNAMIC_DATE_ID.PRECEDING_PERIOD_MATCHING,
  COMPARE_DYNAMIC_DATE_ID.PRECEDING_PERIOD,
  COMPARE_DYNAMIC_DATE_ID.PRECEDING_MONTH,
  COMPARE_DYNAMIC_DATE_ID.PRECEDING_YEAR,
  COMPARE_DYNAMIC_DATE_ID.PRECEDING_YEAR_MATCHING_WEEKDAYS,
]

const DEFAULT_DYNAMIC_DATE = DYNAMIC_DATE_ID.CUSTOM_RANGE

const focusStartDateStateAtom = focusAtom(analyticsConfigAtom, (optic) =>
  optic.prop('startDate'),
)

const focusEndDateStateAtom = focusAtom(analyticsConfigAtom, (optic) =>
  optic.prop('endDate'),
)

const focusDynamicDateStateAtom = focusAtom(analyticsConfigAtom, (optic) =>
  optic.prop('dynamicDate'),
)

const focusCompareStartDateStateAtom = focusAtom(analyticsConfigAtom, (optic) =>
  optic.prop('compareStartDate'),
)

const focusCompareEndDateStateAtom = focusAtom(analyticsConfigAtom, (optic) =>
  optic.prop('compareEndDate'),
)

const focusCompareDynamicDateStateAtom = focusAtom(
  analyticsConfigAtom,
  (optic) => optic.prop('compareDynamicDate'),
)

export const dateStateAtom = atom(
  (get) => {
    const { timezone } = get(merchantInfoAtom)
    const startDate = get(focusStartDateStateAtom)
    const endDate = get(focusEndDateStateAtom)
    const dynamicDate = get(focusDynamicDateStateAtom)
    const compareStartDate = get(focusCompareStartDateStateAtom)
    const compareEndDate = get(focusCompareEndDateStateAtom)
    const compareDynamicDate = get(focusCompareDynamicDateStateAtom)

    const dynamicDatePreset = getPresetFromUrlDynamicDate(
      dynamicDate ?? DEFAULT_DYNAMIC_DATE,
    )
    const validDateRange: DateRange = [
      dynamicDatePreset?.value[0] ?? toZonedTime(startDate, timezone),
      dynamicDatePreset?.value[1] ?? toZonedTime(endDate, timezone),
    ]

    const compareDynamicDatePreset = getPresetFromUrlCompareDynamicDate(
      compareDynamicDate ?? DEFAULT_DYNAMIC_DATE,
      validDateRange,
    )
    const validCompareDateRange: DateRange = [
      compareDynamicDatePreset?.value[0] ??
        (compareStartDate ? toZonedTime(compareStartDate, timezone) : null),
      compareDynamicDatePreset?.value[1] ??
        (compareEndDate ? toZonedTime(compareEndDate, timezone) : null),
    ]

    const isCompare = !!validCompareDateRange[0] && !!validCompareDateRange[1]

    return {
      dateRange: validDateRange,
      dynamicDate: dynamicDatePreset?.id,
      compareDateRange: validCompareDateRange,
      compareDynamicDate: compareDynamicDatePreset?.id,
      isCompare,
    }
  },
  (
    _,
    set,
    {
      dateRange,
      compareDateRange,
      compareDynamicDate,
      dynamicDate,
    }: Omit<DateState, 'isCompare'>,
  ) => {
    const [compareStartDate, compareEndDate] = compareDateRange

    if (getIsPopulatedDateRange(dateRange)) {
      set(focusStartDateStateAtom, dateRange[0].toISOString())
      set(focusEndDateStateAtom, dateRange[1].toISOString())
    }

    set(
      focusCompareStartDateStateAtom,
      compareStartDate ? compareStartDate.toISOString() : null,
    )
    set(
      focusCompareEndDateStateAtom,
      compareEndDate ? compareEndDate.toISOString() : null,
    )
    set(focusDynamicDateStateAtom, dynamicDate)
    set(
      focusCompareDynamicDateStateAtom,
      (compareDynamicDate as string) ?? null,
    )
  },
)

export const useDateState = () => useAtomValue(dateStateAtom)

export const useSetDateState = () => {
  const setDateStateAtom = useSetAtom(dateStateAtom)
  const [createAnalyticsConfig] = useCreateAnalyticsConfig()
  const isNewReportFlow = useIsNewReportFlow()

  const setDateState = useCallback(
    (newDateState: Omit<DateState, 'isCompare'>) => {
      setDateStateAtom(newDateState)
      if (!isNewReportFlow) {
        const newAnalyticsConfig = getAnalyticsConfigFromStore()

        const { dateRange, compareDateRange, dynamicDate, compareDynamicDate } =
          newDateState

        const [compareStartDate, compareEndDate] = compareDateRange

        if (getIsPopulatedDateRange(dateRange)) {
          newAnalyticsConfig.startDate = dateRange[0].toISOString()
          newAnalyticsConfig.endDate = dateRange[1].toISOString()
        }

        newAnalyticsConfig.compareStartDate = compareStartDate
          ? compareStartDate.toISOString()
          : null
        newAnalyticsConfig.compareEndDate = compareEndDate
          ? compareEndDate.toISOString()
          : null
        newAnalyticsConfig.dynamicDate = dynamicDate ?? null
        newAnalyticsConfig.compareDynamicDate =
          (compareDynamicDate as string) ?? null

        createAnalyticsConfig(newAnalyticsConfig)
      }
    },
    [createAnalyticsConfig, isNewReportFlow, setDateStateAtom],
  )

  return setDateState
}
