import { useMutation } from '@apollo/client'
import { useToast } from '@chakra-ui/react'
import { ButtonIcon } from 'components/buttons'
import type { ButtonSize } from 'components/buttons/ButtonIcon'
import { graphql } from 'generated/graphql'
import { type ReportWithOwnerFieldsFragment } from 'generated/graphql/graphql'
import { useTrackEvent } from 'graphql/events/useTrackEvent'
import { FAVORITE_REPORTS_QUERY } from 'graphql/reports/useFavoriteReports'
import { getIsTemplateReport } from 'graphql/reports/utils'
import { cloneDeep, set } from 'lodash-es'
import { type ReportState } from '../atoms/reportViewStateAtom'

const FAVORITE_REPORT_MUTATION = graphql(/* GraphQL */ `
  mutation FavoriteReport($params: UpdateFavoriteReportInput!) {
    updateFavoriteReport(params: $params) {
      id
      favorite
      __typename
    }
  }
`)

enum ACTION {
  ADD = 'ADD',
  REMOVE = 'REMOVE',
}

interface ReportFavoriteStarProps {
  report: ReportState
  size?: Extract<ButtonSize, 'xs' | 'sm'>
  onClick?: () => void
}

export const ReportFavoriteStar: React.FC<ReportFavoriteStarProps> = ({
  report,
  size,
  onClick,
}) => {
  const [mutation] = useMutation(FAVORITE_REPORT_MUTATION)
  const toast = useToast()
  const [trackEvent] = useTrackEvent()

  if (!report?.id || getIsTemplateReport(report.owner)) return null

  return (
    <ButtonIcon
      title={report.favorite ? 'Remove favorite' : 'Favorite'}
      name={report.favorite ? 'StarFilledIcon' : 'StarIcon'}
      color={report.favorite ? 'yellow.400' : 'gray.800'}
      size={size ?? 'xs'}
      variant="ghost"
      role="group"
      iconProps={
        report.favorite ? { _groupHover: { color: 'yellow.400' } } : undefined
      }
      onClick={(e) => {
        e.stopPropagation() // stop clicking on report list table
        if (!report.id) return

        onClick?.()
        mutation({
          variables: {
            params: {
              reportId: report.id,
              action: report.favorite ? ACTION.REMOVE : ACTION.ADD,
            },
          },
          optimisticResponse: {
            updateFavoriteReport: {
              id: report.id,
              __typename: 'Report',
              favorite: !report.favorite,
            },
          },
          // update the favorites query for the left sidebar
          update: (cache, { data }) => {
            if (!data?.updateFavoriteReport) return
            cache.updateQuery(
              { query: FAVORITE_REPORTS_QUERY },
              (queryData) => {
                if (!queryData?.viewer?.favoriteReports) return queryData

                const { favoriteReports } = queryData.viewer

                const newFavoriteReport = report.favorite
                  ? favoriteReports.filter((r) => r.id !== report.id)
                  : [
                      {
                        ...report,
                        favorite: true,
                      } as unknown as ReportWithOwnerFieldsFragment,
                    ].concat(favoriteReports)

                const queryDataClone = cloneDeep(queryData)

                set(queryDataClone, 'viewer.favoriteReports', newFavoriteReport)

                return queryDataClone
              },
            )
          },
          onError: () => {
            toast({
              status: 'error',
              description: 'Could not update favorite, please try again',
            })
          },
          onCompleted: () => {
            trackEvent({
              eventName: report.favorite
                ? 'Report Favorite Removed'
                : 'Report Favorite Added',
              eventProperties: {
                reportId: report.id ?? '',
              },
            })
          },
        })
      }}
    />
  )
}
