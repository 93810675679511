import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { ButtonIcon } from 'components/buttons'
import { Button } from 'components/buttons/Button'
import { useTrackEvent } from 'graphql/events/useTrackEvent'
import { type ProductLabel } from '../graphql/fragments'
import { useDeleteProductLabel } from '../graphql/useDeleteProductLabel'

interface DeleteProductLabelProps {
  label: ProductLabel
}

export const DeleteProductLabelModal = ({ label }: DeleteProductLabelProps) => {
  const deleteProductLabel = useDeleteProductLabel()

  const [trackEvent] = useTrackEvent()
  const toast = useToast()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const onDeleteLabel = () => {
    deleteProductLabel({
      variables: {
        id: label.id,
      },
      optimisticResponse: {
        deleteProductLabel: {
          id: label.id,
          __typename: 'ProductLabel',
        },
      },
      update: (cache, { data }) => {
        if (!data?.deleteProductLabel) return

        cache.evict({
          id: cache.identify(data?.deleteProductLabel),
        })
        cache.gc()
      },
      onCompleted: () => {
        trackEvent({
          eventName: 'ProductFeed Product Label Deleted',
          eventProperties: {
            name: label.name,
          },
        })
      },
      onError: () => {
        toast({
          description: 'Could not delete product label. Try again.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      },
    })
  }

  return (
    <>
      <ButtonIcon
        onClick={onOpen}
        size="sm"
        variant="ghost"
        title="Delete product label"
        name="TrashIcon"
      />

      <Modal isOpen={isOpen} onClose={onClose} returnFocusOnClose={false}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Delete product label</ModalHeader>
          <ModalCloseButton />

          <ModalBody>
            The product label will be deleted. Any segmentation rules that uses
            the product label will also be removed.
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="gray"
              variant="outline"
              mr={2}
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              colorScheme="red"
              type="submit"
              onClick={() => {
                onDeleteLabel()
                onClose()
              }}
            >
              Delete product label
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
