import {
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Portal,
} from '@chakra-ui/react'
import { MenuOverlay } from 'components/Dropdown/components/MenuOverlay'
import { Icon } from 'components/Icon/Icon'
import { type ReportState } from 'features/reports/atoms/reportViewStateAtom'
import { useCanEditReport } from 'features/reports/hooks/useCanEditReport'
import { type Dispatch, type FC, type SetStateAction } from 'react'
import { CopyReportURLMenuItem } from './CopyReportURLMenuItem'
import { DeleteReportMenuItem } from './DeleteReportMenuItem'
import { DuplicateReportMenuItem } from './DuplicateReportMenuItem'

interface Props {
  report: ReportState
  userId: string | undefined
  isEditable?: boolean
  setIsEditing?: Dispatch<SetStateAction<boolean>>
}

export const ReportContextualMenu: FC<Props> = ({
  report,
  userId,
  isEditable = false,
  setIsEditing,
}) => {
  const canEditReport = useCanEditReport(report)

  if (!report) return null
  const isOwnUser = userId === report.owner?.id
  // Delete is more restrictive than edit so we need a different check
  const isDeleteAllowed = report.id && isOwnUser

  return (
    <Menu computePositionOnMount={false} isLazy size="sm">
      {({ isOpen }) => (
        <>
          <MenuButton
            onClick={
              (e) => e.stopPropagation() // For example when context menu is used in report list, it will stop the report from being selected on click
            }
            as={IconButton}
            size="sm"
            aria-label="Options"
            icon={
              <Icon
                name="HorizontalMenuIcon"
                tooltipLabel="More actions"
                size="small"
              />
            }
            variant="ghost"
          />
          <Portal>
            <MenuOverlay isOpen={isOpen} />
            <MenuList
              onClick={(e) => e.stopPropagation()}
              zIndex="dropdown" // zIndex is set to dropdown to ensure the menu is below the delete report modal
            >
              {isEditable && report.id && canEditReport && (
                <MenuItem
                  onClick={() => setIsEditing?.((prev) => !prev)}
                  icon={<Icon name="EditIcon" size="small" />}
                >
                  Edit report
                </MenuItem>
              )}
              <DuplicateReportMenuItem report={report} />

              <MenuDivider />

              <CopyReportURLMenuItem reportId={report.id} />

              {isDeleteAllowed && (
                <>
                  <MenuDivider />
                  <DeleteReportMenuItem reportId={report.id} />
                </>
              )}
            </MenuList>
          </Portal>
        </>
      )}
    </Menu>
  )
}
