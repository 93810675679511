import { Box, Image } from '@chakra-ui/react'
import { Button } from 'components/buttons'
import { Typography } from 'components/Typography'
import type React from 'react'
import { AddSegmentationSetModal } from './AddSegmentationSetModal'

export const EmptySegments: React.FC = () => {
  return (
    <Box maxW="380px" mx="auto">
      <Image src="/images/Layers.svg" alt="No segments" boxSize={120} mb={6} />

      <Typography
        color="gray.900"
        fontSize="medium"
        fontWeight={500}
        lineHeight={6}
        mb={2}
      >
        Segmentation Sets
      </Typography>

      <Typography mb={6}>
        A segmentation set defines the rules for how your products should
        automatically be assigned to different product segments. You can create
        multiple sets to use for different product feeds (e.g. one segmentation
        set for Google, another for Facebook).
      </Typography>

      <AddSegmentationSetModal
        modalButton={<Button size="sm">Create new set</Button>}
      />
    </Box>
  )
}
