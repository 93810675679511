import { type DYNAMIC_DATE_ID } from 'constants/getDatePresets'
import { Box, useToast } from '@chakra-ui/react'
import { Button } from 'components/buttons'
import { useProductLabels } from 'features/productFeed/graphql/useProductLabels'
import type { SegmentationSetByIdQueryResult } from 'features/productFeed/graphql/useSegmentationSetById'
import type React from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { Form } from 'react-router-dom'
import { v4 as uuid } from 'uuid'
import { useSetSegmentationRules } from '../../graphql/useSetSegmentationRules'
import { RuleCard } from './RuleCard'
import { type RuleForm } from './types'

interface RulesContainerProps {
  segmentationSet: SegmentationSetByIdQueryResult
  selectedDatePresetId: DYNAMIC_DATE_ID
}

const getEmptyRule = (): RuleForm => ({
  id: uuid(),
  description: '',
  filters: {},
  productLabel: null,
  expiresAt: null,
})

export const RulesContainer: React.FC<RulesContainerProps> = ({
  segmentationSet,
  selectedDatePresetId,
}) => {
  const { productLabels } = useProductLabels()

  const {
    control,
    handleSubmit,
    formState: { isDirty },
  } = useForm<{ rules: RuleForm[] }>({
    defaultValues: {
      rules:
        segmentationSet.rules?.length > 0
          ? segmentationSet.rules
          : [getEmptyRule()],
    },
  })

  const { fields, append, update } = useFieldArray({
    control,
    name: 'rules',
    // the id generated here changes on every change. Which makes the state inside of the cards unstable
    // so we use our own generated id
    keyName: 'internal_id',
  })

  const [setSegmentationRules, setSegmentationRulesState] =
    useSetSegmentationRules()

  const toast = useToast()

  const isSaveButtonDisabled =
    !isDirty && segmentationSet.dynamicDate === selectedDatePresetId

  const onSubmit = ({ rules }: { rules: RuleForm[] }) => {
    setSegmentationRules({
      variables: {
        params: {
          rules: rules.map((rule) => {
            return {
              description: rule.description,
              filters: rule.filters,
              productLabelId: rule.productLabel?.id,
              expiresAt: rule.expiresAt,
            }
          }),
          dynamicDate: selectedDatePresetId,
          segmentationSetId: segmentationSet.id,
        },
      },
      optimisticResponse: {
        setSegmentationRules: rules.map((rule) => ({
          __typename: 'SegmentationRule',
          id: rule.id,
          description: rule.description,
          filters: rule.filters,
          productLabel: rule.productLabel
            ? {
                ...rule.productLabel,
                __typename: 'ProductLabel',
              }
            : null,
          expiresAt: rule.expiresAt,
        })),
      },
      update: (cache, { data }) => {
        cache.modify({
          id: cache.identify({
            __typename: 'SegmentationSet',
            id: segmentationSet.id,
          }),
          fields: {
            rules: () => data?.setSegmentationRules,
          },
        })
      },

      onError: () => {
        toast({
          status: 'error',
          description: 'Failed to save rules',
        })
      },
    })
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Box mb={4}>
        {fields.map((field, index) => (
          <RuleCard
            key={field.id}
            field={field}
            index={index}
            productLabels={productLabels}
            selectedDatePresetId={selectedDatePresetId}
            update={update}
          />
        ))}
      </Box>

      <Box>
        <Button type="button" onClick={() => append(getEmptyRule())}>
          Add rule
        </Button>
      </Box>
      <Button
        type="submit"
        mt={4}
        isDisabled={isSaveButtonDisabled}
        isLoading={setSegmentationRulesState.loading}
      >
        Save rules
      </Button>
    </Form>
  )
}
