import { type RouteObject } from 'react-router-dom'
import { PRODUCT_FEED_ROUTE_FRAGMENTS } from './routePages'
import { ProductFeedView } from './views/ProductFeedView/ProductFeedView'
import { ProductLabelsView } from './views/ProductLabelsView/ProductLabelsView'
import { SegmentationRulesView } from './views/SegmentationRulesView/SegmentationRulesView'
import { SegmentationSetsView } from './views/SegmentationSetsView/SegmentationSetsView'

export const routes: RouteObject[] = [
  {
    index: true,
    element: <ProductFeedView />,
  },
  {
    path: PRODUCT_FEED_ROUTE_FRAGMENTS.PRODUCT_LABELS,
    element: <ProductLabelsView />,
  },
  {
    path: PRODUCT_FEED_ROUTE_FRAGMENTS.SEGMENTATION_SETS,
    children: [
      {
        index: true,
        element: <SegmentationSetsView />,
      },
      {
        path: ':id',
        children: [
          {
            path: PRODUCT_FEED_ROUTE_FRAGMENTS.SEGMENTATION_RULES,
            element: <SegmentationRulesView />,
          },
        ],
      },
    ],
  },
]
