import {
  type DropdownOption,
  type ComboBoxSingleProps,
  type DropdownGroup,
} from 'components/Dropdown'
import { ComboBox, SELECTION_MODE } from 'components/Dropdown/ComboBox/ComboBox'
import {
  getMetricLabelWithAttribution,
  getValidDimensions,
} from 'features/reports/utils/utils'
import { useNormalizedAttributionModels } from 'graphql/statistics/useAttributionModels'
import { useNormalizedDimensions } from 'graphql/statistics/useDimensions'
import {
  type Metric,
  useNormalizedMetrics,
  type AttributedMetric,
} from 'graphql/statistics/useMetrics'
import { groupBy } from 'lodash-es'
import { useMemo } from 'react'

type DimensionMetricAutocompleteProps = Omit<
  ComboBoxSingleProps,
  'setSelected' | 'selectionMode' | 'options'
> & {
  validDimensionIds?: string[]
  validMetricIds?: string[]
  metrics: Metric[]
  setSelected: (item: DropdownOption) => void
}

export const DimensionMetricAutocomplete = ({
  validDimensionIds,
  validMetricIds,
  setSelected,
  metrics,
  ...rest
}: DimensionMetricAutocompleteProps) => {
  const normalizedDimensions = useNormalizedDimensions()
  const dimensions =
    validDimensionIds?.map((id) => normalizedDimensions[id]).filter(Boolean) ||
    Object.values(normalizedDimensions)

  const normalizedMetrics = useNormalizedMetrics()
  const validMetrics =
    validMetricIds?.map((id) => normalizedMetrics[id]).filter(Boolean) ||
    metrics

  const normalizedAttributionModels = useNormalizedAttributionModels()

  const groups: DropdownGroup[] = useMemo(() => {
    const metricGroups = groupBy(validMetrics, (metric) => metric.groupKey)

    return [
      {
        name: 'Dimensions',
        items: getValidDimensions(dimensions, validMetrics).map((dim) => ({
          ...dim,
          name: dim.label,
        })),
      },
      {
        name: 'Metrics',
        items: validMetrics.map((metric) => ({
          ...metric,
          id: metric.key,
          name:
            metricGroups[metric.groupKey]?.length > 1
              ? getMetricLabelWithAttribution(
                  metric,
                  normalizedAttributionModels[
                    (metric as AttributedMetric).attributionId ?? ''
                  ]?.label,
                )
              : metric.label,
        })),
      },
    ]
  }, [dimensions, validMetrics, normalizedAttributionModels])

  return (
    <ComboBox
      {...rest}
      selectionMode={SELECTION_MODE.SINGLE}
      options={groups}
      setSelected={(id) => {
        const item = groups
          .flatMap((group) => group.items)
          .find((item) => item.id === id)

        if (item) {
          setSelected(item)
        }
      }}
    />
  )
}
