import { useUser } from '@clerk/clerk-react'
import { attributionModelsQueryAtom } from 'graphql/statistics/useAttributionModels'
import { dimensionsQueryAtom } from 'graphql/statistics/useDimensions'
import { metricsQueryAtom } from 'graphql/statistics/useMetrics'
import { useInitialQuery } from 'graphql/useInitialQuery'
import { merchantQueryAtom } from 'graphql/useMerchantInfo'
import { useSignOut } from 'hooks/useSignOut'
import { type WritableAtom } from 'jotai'
import { useHydrateAtoms } from 'jotai/utils'
import { useEffect, type PropsWithChildren } from 'react'
import { userAtom } from './atoms'
import { LoaderPage } from './LoaderPage'

export const InitializeAppData = ({ children }: PropsWithChildren) => {
  const { data, loading } = useInitialQuery()
  const { user } = useUser()
  const signOut = useSignOut()

  useEffect(() => {
    if (loading) {
      return
    }
    if (!data?.viewer?.merchant) {
      signOut()
    }
  }, [data?.viewer?.merchant, loading, signOut])

  if (loading || !data?.viewer?.merchant) {
    return <LoaderPage />
  }

  return (
    <HydrateApp
      atomValues={[
        [metricsQueryAtom, data.viewer.merchant.metrics],
        [dimensionsQueryAtom, data.viewer.merchant.dimensions],
        [attributionModelsQueryAtom, data.viewer.merchant.attributionModels],
        [merchantQueryAtom, data.viewer.merchant],
        [userAtom, { externalId: user?.id, id: user?.publicMetadata.dema_id }],
      ]}
    >
      {children}
    </HydrateApp>
  )
}

export const HydrateApp = <
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  T extends (readonly [WritableAtom<unknown, [any], unknown>, unknown])[],
>({
  children,
  atomValues,
}: PropsWithChildren<{ atomValues: T }>) => {
  useHydrateAtoms(atomValues)

  return children
}
