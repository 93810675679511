import { Input } from 'components/Input'
import { Typography } from 'components/Typography'
import { useState } from 'react'

interface RuleDescriptionProps {
  description: string | undefined | null
  onChange: (value: string) => void
}

export const RuleDescription: React.FC<RuleDescriptionProps> = ({
  description,
  onChange,
}) => {
  const [showInput, setShowInput] = useState(!description)

  if (showInput || !description) {
    return (
      <Input
        size="xs"
        placeholder="Edit description"
        value={description ?? ''}
        onChange={(e) => onChange(e.target.value)}
      />
    )
  }

  return (
    <Typography
      fontSize="sm"
      lineHeight={5}
      color="gray.800"
      onClick={() => {
        setShowInput(true)
      }}
      aria-label="Edit description"
      cursor="pointer"
    >
      {description}
    </Typography>
  )
}
