import config from 'config'
import { Routes as Auth } from 'features/auth'
import { routes as customDashboardRoutes } from 'features/customDashboard/Routes'
import { routes as optimizationsRoutes } from 'features/optimizations/Routes'
import { routes as productFeedRoutes } from 'features/productFeed/Routes'
import { routes as reportsRoutes } from 'features/reports/Routes'
import { routes as settingsRoutes, teamRoutes } from 'features/settings/Routes'
import { lazy } from 'react'
import { createBrowserRouter } from 'react-router-dom'
import { AppWrapper } from './AppWrapper'
import { AuthenticatedOutlet } from './AuthenticatedOutlet'
import { NotFound } from './NotFound'
import { UnexpectedError } from './UnexpectedError'

export const router = createBrowserRouter(
  [
    { path: 'auth/*', Component: Auth, errorElement: <UnexpectedError /> },
    {
      path: '*',
      Component: AuthenticatedOutlet,
      errorElement: <UnexpectedError />,
      children: [
        {
          Component: AppWrapper,
          children: [
            {
              index: true,
              Component: lazy(() => import('features/dashboard/Dashboard')),
            },
            {
              path: 'optimizations',
              Component: lazy(
                () => import('features/optimizations/Optimizations'),
              ),
              children: optimizationsRoutes,
            },
            {
              path: 'product-feed',
              Component: lazy(() => import('features/productFeed/ProductFeed')),
              children: productFeedRoutes,
            },
            {
              path: 'custom-dashboard',
              Component: lazy(
                () => import('features/customDashboard/CustomDashboardView'),
              ),
              children: customDashboardRoutes,
            },
            {
              path: 'reports',
              Component: lazy(() => import('features/reports/Reports')),
              children: reportsRoutes,
            },
            {
              path: 'settings',
              Component: lazy(() => import('features/settings/Settings')),
              children: [...settingsRoutes, ...teamRoutes],
            },
            { path: '*', Component: NotFound },
          ],
        },
      ],
    },
  ],
  { basename: config.APP_BASENAME },
)
