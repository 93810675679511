import { Box, Table, Tbody, Th, Thead, Tr } from '@chakra-ui/react'
import type React from 'react'
import { useSegmentationSets } from '../graphql/useSegmentationSets'
import { EmptySegments } from './EmptySegments'
import { SegmentRow } from './SegmentRow'

export const SegmentationList: React.FC = () => {
  const { segmentationSets, query } = useSegmentationSets()

  if (query.loading) {
    return <Box>loading...</Box>
  }

  if (segmentationSets.length === 0) {
    return (
      <Box py="120px" px={4}>
        <EmptySegments />
      </Box>
    )
  }

  return (
    <Table mt={6}>
      <Thead>
        <Tr>
          <Th>Name</Th>
          <Th>Last updated</Th>
          <Th>
            <Box srOnly>Actions</Box>
          </Th>
        </Tr>
      </Thead>
      <Tbody>
        {segmentationSets.map((segmentationSet) => (
          <SegmentRow
            key={segmentationSet.id}
            segmentationSet={segmentationSet}
          />
        ))}
      </Tbody>
    </Table>
  )
}
