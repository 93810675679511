import {
  Box,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
} from '@chakra-ui/react'
import { Button } from 'components/buttons/Button'
import { type IconName } from 'components/Icon/Icon'
import Input from 'components/Input/Input'

import { ColorPicker } from 'components/Pickers/ColorPicker'
import { GenericPickerModal } from 'components/Pickers/GenericPickerModal'
import { IconPicker } from 'components/Pickers/IconPicker'
import { Typography } from 'components/Typography'

import {
  type SegmentationSetFieldsFragment,
  type CreateSegmentationSetInput,
} from 'generated/graphql/graphql'
import {
  useDefaultDisclosure,
  type DefaultDisclosure,
} from 'hooks/useDefaultDisclosure'
import { type FC, useState, type ReactElement, useRef } from 'react'
import { colorTheme } from 'ui/theme/colors'
import { PRODUCT_FEED_DEFAULT_DATE_RANGE } from '../consts'

const segmentationSetColors: string[] = [
  colorTheme.gray[500],
  colorTheme.purple[500],
  colorTheme.magenta[500],
  colorTheme.red[400],
  colorTheme.orange[500],
  colorTheme.yellow[600],
  colorTheme.green[500],
  colorTheme.dema[600],
  colorTheme.cyan[600],
  colorTheme.blue[600],
  colorTheme.black,
]

const segmentationSetIconNames: IconName[] = [
  'PlatformLogoGoogleIcon',
  'PlatformLogoMetaIcon',
  'PlatformLogoMicrosoftIcon',
  'PlatformLogoPinterestIcon',
  'PlatformLogoSnapchatIcon',
  'PlatformLogoTikTokIcon',
  'BikeIcon',
  'MotorcycleIcon',
  'CarIcon',
  'ShippingTruckIcon',
  'SailboatIcon',
  'RocketIcon',
  'RobotIcon',
  'LayersIcon',
  'RulerIcon',
  'ChartPieIcon',
  'ChartDonutIcon',
  'BarChartIcon',
  'BlockIcon',
  'ExclamationCircleIcon',
  'ExclamationTriangleIcon',
  'ParkingIcon',
  'DiamondIcon',
  'MedalIcon',
  'HeartIcon',
  'StarIcon',
  'BadgeIcon',
  'DollarCoinIcon',
  'PiggyBankIcon',
  'WalletIcon',
  'SignIcon',
  'MegaphoneIcon',
  'SuitcaseIcon',
  'ShoppingBagIcon',
  'ShirtIcon',
  'Percent1Icon',
  'BadgeDiscountIcon',
  'TagIcon',
  'WarehouseIcon',
  'EarthIcon',
  'Flower1Icon',
  'Flower2Icon',
  'TreeIcon',
  'LeafIcon',
]

export type UpdatedSegmentationSet = CreateSegmentationSetInput & {
  id?: string
}

const defaultSegmentationSet: UpdatedSegmentationSet = {
  iconColor: segmentationSetColors[0],
  name: '',
  iconName: segmentationSetIconNames[0],
  dynamicDate: PRODUCT_FEED_DEFAULT_DATE_RANGE,
}

type SegmentationSetPickerProps = {
  currentSegmentationSet: SegmentationSetFieldsFragment | undefined
  modalButton: ReactElement
  isEditMode?: boolean
  onApply: (segmentationSet: UpdatedSegmentationSet) => void
  disclosure?: DefaultDisclosure
}

export const SegmentationSetPicker: FC<SegmentationSetPickerProps> = ({
  currentSegmentationSet,
  modalButton,
  isEditMode,
  onApply,
  disclosure,
}) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const [segmentationSet, setSegmentationSet] =
    useState<UpdatedSegmentationSet>(
      currentSegmentationSet ?? defaultSegmentationSet,
    )
  const { isOpen, onOpen, onClose } = useDefaultDisclosure(disclosure)

  return (
    <GenericPickerModal
      isOpen={isOpen}
      onClose={onClose}
      onOpen={onOpen}
      modalButton={modalButton}
      setToDefaultState={() => {
        if (!currentSegmentationSet) {
          setSegmentationSet(defaultSegmentationSet)
        }
      }}
      initialFocusRef={inputRef}
    >
      <ModalHeader>
        {isEditMode ? 'Edit segmentation set' : 'Add segmentation set'}
      </ModalHeader>
      <ModalCloseButton />

      <ModalBody>
        <Typography mb={4}>
          Choose a color and an icon for the segmentation set.
        </Typography>

        <Box mb={6}>
          <ColorPicker
            colors={segmentationSetColors}
            selectedColor={segmentationSet.iconColor}
            setSelectedColor={(iconColor) =>
              setSegmentationSet((segmentationSet) => ({
                ...segmentationSet,
                iconColor,
              }))
            }
          />
        </Box>

        <Box mb={8}>
          <IconPicker
            iconColor={segmentationSet.iconColor}
            icons={segmentationSetIconNames}
            selectedIcon={segmentationSet.iconName}
            setSelectedIcon={(iconName) =>
              setSegmentationSet((segmentationSet) => ({
                ...segmentationSet,
                iconName,
              }))
            }
          />
        </Box>

        <Input
          ref={inputRef}
          label="Segmentation set name"
          title="Segmentation set name"
          placeholder=""
          value={segmentationSet.name}
          maxLength={50}
          onChange={(e) =>
            setSegmentationSet((segmentationSet) => ({
              ...segmentationSet,
              name: e.target.value,
            }))
          }
        />
      </ModalBody>

      <ModalFooter>
        <Button colorScheme="gray" variant="outline" mr={2} onClick={onClose}>
          Cancel
        </Button>
        <Button
          isDisabled={
            !segmentationSet.name ||
            !segmentationSet.iconColor ||
            !segmentationSet.iconName
          }
          variant="solid"
          type="submit"
          colorScheme="primary"
          onClick={() => {
            onApply(segmentationSet)
            onClose()
          }}
        >
          {isEditMode ? 'Save' : 'Add segmentation set'}
        </Button>
      </ModalFooter>
    </GenericPickerModal>
  )
}
