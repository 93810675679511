import {
  Flex,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuList,
  MenuOptionGroup,
  Portal,
  Skeleton,
  useDisclosure,
} from '@chakra-ui/react'
import { useOrganization, useUser } from '@clerk/clerk-react'
import { Button } from 'components/buttons'
import { MenuItem } from 'components/Dropdown/components/MenuItem'
import { MenuOverlay } from 'components/Dropdown/components/MenuOverlay'
import { Icon, type IconName } from 'components/Icon/Icon'
import { type ReportState } from 'features/reports/atoms/reportViewStateAtom'
import { ClerkImage } from 'features/reports/components/ClerkImage'
import { useCanEditReport } from 'features/reports/hooks/useCanEditReport'
import { type MerchantFieldsFragment } from 'generated/graphql/graphql'
import {
  getMerchantVisibilityFromOrganization,
  getReportVisibilityType,
  ORGANIZATION_ID,
  REPORT_VISIBILITY,
} from 'graphql/reports/utils'
import { type MerchantTeam } from 'graphql/teams/useMerchantTeams'
import { isEqual } from 'lodash-es'
import { useMemo, useRef, useState, type FC } from 'react'

// Gets set in the table row hover
export const VISIBILITY_ICON_BG_VARIABLE = '--visibility-icon-overlap-bg'

type Option = {
  id: string
  name: string
  iconName?: IconName
  iconColor?: string
}

type TeamIcon = {
  name: IconName
  color: string
}

export const nonTeamVisibilityOptions = [
  {
    id: REPORT_VISIBILITY.PRIVATE,
    name: 'Private',
    iconName: 'PadlockIcon',
    iconColor: 'grey.800',
    value: [],
  },
  {
    id: REPORT_VISIBILITY.ORGANISATION,
    name: 'Everyone',
    value: [ORGANIZATION_ID],
  },
] satisfies (Option & { value: string[] })[]

const OverlappingIcons: FC<{ icons?: TeamIcon[] }> = ({ icons = [] }) => (
  <Flex overflow="hidden">
    {icons.map((icon, index) => (
      <Flex key={index} maxW={2.5} _last={{ maxW: 'full' }}>
        <Icon
          size="small"
          bg={`var(${VISIBILITY_ICON_BG_VARIABLE}, white)`}
          _groupHover={{ bg: 'grey.100' }}
          _groupActive={{ bg: 'grey.300' }}
          // we dont want the hover/active state if ifs disabled as it causes inconstant css issues
          sx={{
            '[role=group]:disabled &': {
              color: 'grey.300',
            },
            '[role=group]:disabled:hover &': {
              bg: 'unset',
            },
            '[role=group]:disabled:focus &': {
              bg: 'unset',
            },
          }}
          name={icon.name}
          hexColor={icon.color}
        />
      </Flex>
    ))}
  </Flex>
)

type Props = {
  report: ReportState
  teamOptions: MerchantTeam[]
  isLoading?: boolean
  onVisibilityChanged: (
    visibility: MerchantTeam[] | MerchantFieldsFragment[],
  ) => void
}

export const ReportVisibility: FC<Props> = ({
  report,
  teamOptions,
  isLoading,
  onVisibilityChanged,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { organization } = useOrganization()
  const selectedItemRef = useRef<HTMLButtonElement>(null)
  const { user } = useUser()
  const userId = user?.publicMetadata.dema_id

  const { visibility, owner } = report ?? {}
  const visibilityType = getReportVisibilityType(visibility)
  const canEditReport = useCanEditReport(report)
  const isOwnReport = owner?.id === userId

  const staticOption = useMemo(
    () =>
      nonTeamVisibilityOptions.find((option) => visibilityType === option.id),
    [visibilityType],
  )

  const selectedIds = useMemo(() => {
    if (staticOption) {
      return staticOption.value
    }

    return visibility?.map((v) => v.id) ?? []
  }, [visibility, staticOption])

  const [draftSelected, setDraftSelected] = useState<string[]>(selectedIds)

  const updateVisibility = async (newSelectedIds: string[]) => {
    if (isEqual(selectedIds, newSelectedIds)) return

    const nonTeamOption = nonTeamVisibilityOptions.find(
      (option) => option.value === newSelectedIds,
    )
    const nonTeamVisibility =
      nonTeamOption?.id === REPORT_VISIBILITY.ORGANISATION
        ? getMerchantVisibilityFromOrganization(organization)
        : undefined
    const newVisibility =
      nonTeamVisibility ??
      teamOptions.filter((team) => newSelectedIds.includes(team.id))

    onVisibilityChanged(newVisibility)
    setDraftSelected(newVisibility.map((v) => v.id))
  }

  const { buttonText, buttonIcons } = useMemo(() => {
    const selectedOptions = staticOption
      ? [staticOption]
      : teamOptions.filter((team) => selectedIds.includes(team.id))

    const buttonText =
      selectedIds.length > 1
        ? `${selectedIds.length} teams`
        : selectedOptions[0]?.name

    const buttonIcons = selectedOptions
      .slice(0, 4) // Show max 4 icons
      .map(({ iconName, iconColor }) =>
        iconName && iconColor
          ? { name: iconName, color: iconColor }
          : undefined,
      )
      .filter((icon?: TeamIcon): icon is TeamIcon => !!icon)

    return { buttonText, buttonIcons }
  }, [staticOption, teamOptions, selectedIds])

  return (
    <Menu
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={() => {
        if (draftSelected) updateVisibility(draftSelected)
        onClose()
      }}
      isLazy
      size="sm"
      placement="bottom-start"
      initialFocusRef={selectedItemRef}
      closeOnSelect={false}
    >
      {isLoading ? (
        <Skeleton mx={2} w="120px" minH={6} />
      ) : (
        <MenuButton
          as={Button}
          role="group"
          isDisabled={!canEditReport}
          colorScheme="grey"
          variant="ghost"
          size="sm"
          px={2}
          bg={isOpen ? 'grey.200' : 'inherit'}
          minW="max-content"
          leadingComponent={
            visibilityType === REPORT_VISIBILITY.ORGANISATION ? (
              <ClerkImage
                imageUrl={organization?.imageUrl}
                type="organisation"
              />
            ) : (
              <OverlappingIcons icons={buttonIcons} />
            )
          }
          onClick={(e) => e.stopPropagation()}
        >
          {buttonText}
        </MenuButton>
      )}
      <Portal>
        <MenuOverlay isOpen={isOpen} />
        <MenuList onClick={(e) => e.stopPropagation()}>
          <MenuGroup>
            {(isOwnReport
              ? nonTeamVisibilityOptions
              : nonTeamVisibilityOptions.filter(
                  (option) => option.id !== REPORT_VISIBILITY.PRIVATE,
                )
            ).map((option) => (
              <MenuItem
                as={Button}
                option={option}
                key={option.id}
                ref={selectedIds === option.value ? selectedItemRef : undefined}
                onClick={() => {
                  updateVisibility(option.value)
                  onClose()
                }}
                isSelected={isEqual(draftSelected, option.value)}
                leftItem={
                  option.id === REPORT_VISIBILITY.ORGANISATION ? (
                    <ClerkImage
                      imageUrl={organization?.imageUrl}
                      type="organisation"
                    />
                  ) : (
                    option.iconName && (
                      <Icon
                        size="small"
                        name={option.iconName as IconName}
                        hexColor={option.iconColor}
                      />
                    )
                  )
                }
              />
            ))}
          </MenuGroup>
          {teamOptions.length > 0 && (
            <>
              <MenuDivider />
              <MenuOptionGroup type="checkbox">
                {teamOptions.map((team) => {
                  const selected = draftSelected ?? []
                  const isSelected = selected.includes(team.id)

                  return (
                    <MenuItem
                      option={team}
                      key={team.id}
                      ref={
                        selected[0] === team.id ? selectedItemRef : undefined
                      }
                      isOption={true}
                      isSelected={isSelected}
                      onClick={() => {
                        setDraftSelected(
                          isSelected
                            ? selected.filter((item) => item !== team.id)
                            : [...selected, team.id],
                        )
                      }}
                      leftItem={
                        <Icon
                          size="small"
                          name={team.iconName}
                          hexColor={team.iconColor}
                        />
                      }
                    />
                  )
                })}
              </MenuOptionGroup>
            </>
          )}
        </MenuList>
      </Portal>
    </Menu>
  )
}
