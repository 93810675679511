import { Flex } from '@chakra-ui/react'
import { Button } from 'components/buttons'
import { Typography } from 'components/Typography'
import type React from 'react'
import { AddSegmentationSetModal } from './AddSegmentationSetModal'

export const SegmentationHeader: React.FC = () => {
  return (
    <Flex
      justifyContent="space-between"
      alignItems="center"
      py={2}
      px={6}
      borderBottom="1px solid"
      borderColor="gray.200"
    >
      <Typography
        color="gray.900"
        fontSize="medium"
        fontWeight={500}
        lineHeight={6}
      >
        Segmentation Sets
      </Typography>

      <AddSegmentationSetModal
        modalButton={
          <Button
            size="sm"
            leadingIcon={{ name: 'PlusSmallIcon' }}
            colorScheme="gray"
          >
            New set
          </Button>
        }
      />
    </Flex>
  )
}
