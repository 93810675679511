import { Box, Flex } from '@chakra-ui/react'
import config from 'config'
import CopilotSidebar from 'features/copilot/CopilotSidebar'
import {
  reportIdAtom,
  reportNameAtom,
} from 'features/reports/hooks/useReportState'
import { useTrackEvent } from 'graphql/events/useTrackEvent'
import { useAtomValue } from 'jotai'
import { Suspense, useEffect, useRef, type FC } from 'react'
import { hotjar } from 'react-hotjar'
import { Outlet, useLocation } from 'react-router-dom'
import { Header } from 'shared/Header'
import { LoaderPage } from './LoaderPage'

export const AppWrapper: FC = () => {
  const currentPathname = useRef<null | string>(null)
  const location = useLocation()
  const [trackEvent] = useTrackEvent()
  const reportId = useAtomValue(reportIdAtom)
  const reportName = useAtomValue(reportNameAtom)

  useEffect(() => {
    if (
      config.APP_ENV === 'production' &&
      config.HOTJAR_ID &&
      config.HOTJAR_SV
    ) {
      hotjar.initialize({ id: config.HOTJAR_ID, sv: config.HOTJAR_SV })
    }
  }, [location.pathname])

  useEffect(() => {
    // to avoid infinite loop
    if (currentPathname.current !== location.pathname) {
      const isReportView = location.pathname.startsWith('/reports')

      // Skip tracking page view in report view until report is loaded
      if (isReportView && !reportId) {
        return
      }
      currentPathname.current = location.pathname

      trackEvent({
        eventName: ' Page Viewed', //Todo: Make it possible to send this without space
        eventProperties: {
          pathname: location.pathname,
          search: location.search,
          reportId: isReportView && reportId ? reportId : undefined,
          reportName: isReportView && reportName ? reportName : undefined,
        },
      })
    }
  }, [trackEvent, location.pathname, location.search, reportId, reportName])

  return (
    <Box
      h="100vh"
      w="100vw"
      display="grid"
      gridTemplateRows="auto 1fr"
      overflow="hidden"
    >
      <Header />
      <Suspense fallback={<LoaderPage />}>
        <Flex
          w="100vw"
          overflowY="auto"
          sx={{
            '& > *': {
              overflowY: 'auto',
            },
          }}
        >
          <Outlet />
          <CopilotSidebar />
        </Flex>
      </Suspense>
    </Box>
  )
}
