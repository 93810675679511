import { useMutation } from '@apollo/client'
import { graphql } from 'generated/graphql'

const CREATE_SEGMENTATION_SET_MUTATION = graphql(/* GraphQL */ `
  mutation CreateSegmentationSet($params: CreateSegmentationSetInput!) {
    createSegmentationSet(params: $params) {
      ...SegmentationSetFields
    }
  }
`)

export const useCreateSegmentationSet = () => {
  const [createSegmentationSet] = useMutation(CREATE_SEGMENTATION_SET_MUTATION)

  return createSegmentationSet
}
