import { Box, Flex } from '@chakra-ui/react'
import { useBreakpoint } from 'hooks/useBreakpoint'
import { AccountMenu } from './AccountMenu/AccountMenu'
import { HEADER_HEIGHT } from './consts'
import { CopilotButton } from './CopilotButton/CopilotButton'
import { IntercomButton } from './IntercomButton/IntercomButton'
import { MenuDrawerIcon } from './MenuDrawer/MenuDrawerIcon'
import { NavLinks } from './NavLinks'
import { OrganizationLegend } from './OrganizationLegend'

export const Header = () => {
  const [isLargerThanLg] = useBreakpoint('lg')

  return (
    <Box as="header" w="100vw">
      <Flex
        pl={4}
        h={HEADER_HEIGHT}
        bg="white"
        color="white"
        alignItems="center"
        borderBottom="solid 1px"
        borderBottomColor="gray.200"
        justifyContent="space-between"
      >
        {isLargerThanLg ? (
          <Flex h="full" alignItems="center" gap={16}>
            <OrganizationLegend />
            <NavLinks />
          </Flex>
        ) : (
          <MenuDrawerIcon />
        )}

        <Flex h="full" alignItems="center">
          <CopilotButton />
          <IntercomButton />
          <AccountMenu />
        </Flex>
      </Flex>
    </Box>
  )
}
