import { type DYNAMIC_DATE_ID, getDatePreset } from 'constants/getDatePresets'
import {
  Box,
  Flex,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Portal,
  Stack,
} from '@chakra-ui/react'
import { Alert } from 'components/Alert/Alert'
import { Button, ButtonIcon } from 'components/buttons'
import { SingleDatepicker } from 'components/Datepicker/SingleDatepicker/SingleDatepicker'
import { MenuOverlay } from 'components/Dropdown/components/MenuOverlay'
import { type BaseFilterProps } from 'components/Filters/types'
import { Icon, type IconName } from 'components/Icon/Icon'
import { Typography } from 'components/Typography'
import { addDays, startOfDay } from 'date-fns'
import { fromZonedTime } from 'date-fns-tz'
import { type ProductLabel } from 'features/productFeed/graphql/fragments'
import { AddProductLabelModal } from 'features/productFeed/views/ProductLabelsView/components/AddProductLabelModal'
import { AddFilterButton } from 'features/reports/components/Filters/AddFilterButton'
import { SelectedFilterChip } from 'features/reports/components/Filters/SelectedFilterChip/SelectedFilterChip'
import { addNewFilter } from 'features/reports/hooks/filters/useAddFilter'
import { removeFilter } from 'features/reports/hooks/filters/useRemoveFilter'
import { getValidMetricProcessors } from 'features/reports/utils/utils'
import { useNormalizedDimensions } from 'graphql/statistics/useDimensions'
import { useNormalizedMetrics } from 'graphql/statistics/useMetrics'
import { useMerchantInfo } from 'graphql/useMerchantInfo'
import {
  type UseFieldArrayUpdate,
  type FieldArrayWithId,
} from 'react-hook-form'
import { formatDateTime } from 'utils/chart/common'
import { RuleDescription } from './RuleDescription'
import { type RuleForm } from './types'

interface RuleCardProps {
  field: FieldArrayWithId<
    {
      rules: RuleForm[]
    },
    'rules',
    'internal_id'
  >
  index: number
  productLabels: ProductLabel[]
  update: UseFieldArrayUpdate<
    {
      rules: RuleForm[]
    },
    'rules'
  >
  selectedDatePresetId: DYNAMIC_DATE_ID
}

export const RuleCard: React.FC<RuleCardProps> = ({
  field,
  index,
  productLabels,
  update,
  selectedDatePresetId,
}) => {
  const dateRange = getDatePreset(selectedDatePresetId)
  const normalizedMetrics = useNormalizedMetrics()
  const normalizedDimensions = useNormalizedDimensions()
  const validDimensionIds = Object.keys(normalizedDimensions)
  const validMetricProcessors = getValidMetricProcessors(
    Object.values(normalizedDimensions),
  )
  const validMetricIds = Object.values(normalizedMetrics)
    .filter((metric) => {
      let isDisabled = false

      if ('dependencies' in metric && validMetricProcessors) {
        isDisabled = !metric.dependencies.every((dependency) =>
          validMetricProcessors.includes(dependency),
        )
      }

      return !isDisabled
    })
    .map((metric) => metric.key)

  const { timezone } = useMerchantInfo()

  const addFilterHandler = (
    args: Parameters<BaseFilterProps['addFilter']>[0],
  ) => {
    const { newFilters } = addNewFilter({
      ...args,
      filters: field.filters,
      normalizedMetrics,
    })

    update(index, {
      ...field,
      filters: newFilters,
    })
  }

  const removeFilterHandler = (
    args: Parameters<BaseFilterProps['removeFilter']>[0],
  ) => {
    const newFilters = removeFilter({
      ...args,
      filters: field.filters,
    })

    update(index, { ...field, filters: newFilters })
  }

  const filters = Object.entries(field.filters)

  const isExpired = field.expiresAt && new Date(field.expiresAt) < new Date()

  return (
    <Stack
      p={4}
      gap={4}
      border="solid 1px"
      borderColor="gray.200"
      borderBottom="none"
      _last={{
        borderBottom: 'solid 1px',
        borderColor: 'gray.200',
      }}
    >
      <Box>
        <Typography fontSize="medium" lineHeight={6} color="gray.900" mb={1}>
          Rule {index + 1}
        </Typography>

        <RuleDescription
          description={field.description}
          onChange={(value) => update(index, { ...field, description: value })}
        />

        {isExpired && (
          <Box mt={4}>
            <Alert
              size="sm"
              status="error"
              closable={false}
              content="This rule has expired and is no longer applied."
            />
          </Box>
        )}
      </Box>

      <Box>
        <Typography
          fontSize="xs"
          lineHeight={4}
          color="gray.700"
          fontWeight={500}
          mb={1}
        >
          Filters
        </Typography>

        <Flex flexWrap="wrap" gap={2}>
          {filters.map(([filterId, filterValues]) =>
            filterValues.map((filter, index) => (
              <SelectedFilterChip
                key={filterId + index}
                filter={filter}
                filterId={filterId}
                filterIndex={index}
                filters={field.filters}
                metricShouldShowAttribution={false}
                addFilter={addFilterHandler}
                removeFilter={removeFilterHandler}
              />
            )),
          )}

          <AddFilterButton
            customMenuButton={
              filters.length > 0 ? (
                <MenuButton
                  as={ButtonIcon}
                  name={'FilterIcon' satisfies IconName}
                  size="sm"
                  variant="ghost"
                  colorScheme="gray"
                />
              ) : (
                <MenuButton
                  as={Button}
                  size="sm"
                  colorScheme="gray"
                  leadingIcon={{
                    name: 'FilterIcon',
                    size: 'small',
                  }}
                >
                  Add filter
                </MenuButton>
              )
            }
            metrics={[]}
            validDimensionIds={validDimensionIds}
            validMetricIds={validMetricIds}
            dateRange={dateRange.value}
            filters={field.filters}
            addFilter={addFilterHandler}
            removeFilter={removeFilterHandler}
          />
        </Flex>
      </Box>

      <Box>
        <Typography
          fontSize="xs"
          lineHeight={4}
          color="gray.700"
          fontWeight={500}
          mb={1}
        >
          Product label
        </Typography>

        <Menu size="sm" placement="bottom-start">
          {({ isOpen }) => (
            <>
              <MenuButton
                as={Button}
                flexShrink={0}
                size="sm"
                aria-label="Actions"
                variant="solid"
                colorScheme="gray"
                leadingIcon={{
                  name: field.productLabel?.iconName ?? 'TagIcon',
                  color: field.productLabel?.iconColor ?? undefined,
                }}
              >
                {field.productLabel?.name ?? 'Add product label'}
              </MenuButton>
              <Portal>
                <MenuOverlay isOpen={isOpen} />
                <MenuList>
                  {productLabels?.length > 0 ? (
                    <>
                      {productLabels.map((productLabel) => (
                        <MenuItem
                          key={productLabel.id}
                          onClick={() =>
                            update(index, {
                              ...field,
                              productLabel: productLabel,
                            })
                          }
                          icon={
                            <Icon
                              name={productLabel.iconName}
                              color={productLabel.iconColor}
                              size="small"
                            />
                          }
                        >
                          {productLabel.name}
                        </MenuItem>
                      ))}

                      <MenuDivider />
                    </>
                  ) : null}

                  <AddProductLabelModal
                    modalButton={
                      <MenuItem
                        icon={<Icon name="PlusSmallIcon" size="small" />}
                      >
                        Add label
                      </MenuItem>
                    }
                    onCompleted={(label) =>
                      update(index, {
                        ...field,
                        productLabel: label,
                      })
                    }
                  />
                </MenuList>
              </Portal>
            </>
          )}
        </Menu>
      </Box>

      <Box>
        <Typography
          fontSize="xs"
          lineHeight={4}
          color="gray.700"
          fontWeight={500}
          mb={1}
        >
          Expiry date
        </Typography>

        <SingleDatepicker
          selectedDate={field.expiresAt ? new Date(field.expiresAt) : null}
          setSelectedDate={(value) => {
            update(index, {
              ...field,
              expiresAt: value
                ? fromZonedTime(startOfDay(value), timezone).toISOString()
                : null,
            })
          }}
          minDate={addDays(new Date(), 1)}
          placement="bottom-start"
          customButton={
            <Button
              size="sm"
              leadingIcon={{
                name: isExpired ? 'CalendarBlockIcon' : 'CalendarBlankIcon',
                color: isExpired ? 'red.500' : undefined,
              }}
              colorScheme="gray"
            >
              {field.expiresAt
                ? formatDateTime({
                    start: new Date(field.expiresAt),
                    end: new Date(field.expiresAt),
                    isPerHour: false,
                  })
                : 'Set expiry date'}
            </Button>
          }
        />
      </Box>
    </Stack>
  )
}
